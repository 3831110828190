import React, {useEffect, useState} from 'react';
import likeIcon from "./like.svg";
import likedIcon from "./liked.svg";
import closeIcon from "../../assets/X.png";

const Modal = ({content, setSelectedContent, setIsModalOpen}) => {
    //const [content] = useState(Days.filter(item => item.id === parseInt(contentIndex)))
    const [liked, setLiked] = useState(false);

    useEffect(()=>{console.log(content)});

    return (
        <>
            <div
                className={'absolute flex justify-center items-center left-0 right-0 top-0 min-h-screen z-[150] bg-black/80 md:fixed'}>
                <div
                    className={"max-w-[950px] md:max-h-[95vh] md:mx-auto relative md:rounded-xl p-10 md:px-28 md:py-10 md:overflow-y-auto rounded-lg m-5 bg-center bg-cover bg-modal"}>
                    <button onClick={() => {
                        setSelectedContent(null);
                        setIsModalOpen(false);
                    }}><img src={closeIcon} alt="" className={'absolute right-5 top-5'}/>
                    </button>
                    {content}
                    <button className={'flex justify-center items-center gap-2 my-6'} onClick={() => {
                        setLiked((liked) => !liked);
                    }}>
                        <img src={liked ? likedIcon : likeIcon} alt="kedvelés" width={30}/><span>{liked ? "Kedvelted" : "Kedvelés"}</span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default Modal;