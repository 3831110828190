import BallsGame from "../Components/Game/BallsGame";
import Game2 from "../Components/Game/Game2";
const Game = () => {
    return (
        <div>
            <h3>Mikulás játék</h3>
            <iframe src="HTML5/index.html" width='100%' height='600px' frameBorder="0" className={'my-5'}/>
            <h4>Segíts a Mikulásnak eljuttatni az ajándékokat a kéményekbe.</h4>
        </div>
    );
};

export const Days = [
    {
        id: 2,
        title: "Különleges vegán recept karácsonyra - Vegán pásztorpite lencséből",
        content:
            <>
                <h2>Különleges vegán recept karácsonyra - Vegán pásztorpite lencséből</h2>
                <div>
                    <img src="assets/bayer/vegan.jpg" alt="" className={'my-4 rounded-lg'}/>
                </div>
                <h4>A karácsonyi ebéd vagy vacsora vegánul is lehet kulináris élmény, íme egy izgalmas, ámde könnyen
                    elkészíthető fogás.</h4>
                <div className="grid md:grid-cols-4 ">
                    <div>
                        <h3>Hozzávalók</h3>
                        <h4 className="mb-2">A töltelékhez:</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>20 dkg száraz lencse</li>
                            <li>25 dkg barna csiperkegomba</li>
                            <li>20 dkg sárgarépa, takarosra kockázva</li>
                            <li>15 dkg zöldborsó</li>
                            <li>3 evőkanál sűrített paradicsom</li>
                            <li>2 nagy fej hagyma (bármilyen: vörös, lila, mogyoró, stb.)</li>
                            <li>4-5 gerezd zúzott fokhagyma (ízlés szerint lehet több vagy kevesebb is)</li>
                            <li>1-1 teáskanál szárított rozmaring és kakukkfű (rozmaring- vagy kakukkfűutálók
                                helyettesíthetik más zöldfűszerekkel is)
                            </li>
                            <li>2-3 babérlevél</li>
                            <li>1 liter zöldségalaplé (ízfokozó- és tartósítószermentes zöldségleveskockából vagy
                                poralapból)
                            </li>
                            <li>1-1 teáskanál édes fűszerpaprika és füstölt paprika</li>
                            <li>2 evőkanál szójaszósz</li>
                            <li>2 evőkanál sörélesztőpehely</li>
                            <li>2 evőkanál olívaolaj</li>
                            <li>másfél dl vörösbor</li>
                            <li>só</li>
                            <li>frissen őrölt bors</li>

                        </ul>
                        <h4 className="mb-2">A tetejére:</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>1 kg főzni való krumpli</li>
                            <li>4-5 evőkanál jó minőségű vegán margarin (ha nem jutsz hozzá, inkább olívaolajat
                                használj)
                            </li>
                            <li>4 evőkanál sörélesztőpehely</li>
                            <li>só</li>
                            <li>frissen őrölt bors</li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>A töltelékkel kezdjük: a hagymát kockázd finomra, és a legnagyobb, magasított falú
                            serpenyődben
                            kezdd el alacsony lángon pirítani egy-két csipet sóval. Miközben pirul, a gombát a botmixer
                            késes aprítójában aprítsd össze, vagy ha ilyened nincs, akkor vágd apróra.
                        </p>
                        <p>
                            Ha a hagyma már 7-8 perce pirul, add hozzá a gombát, még egy evőkanál olívaolajat és két
                            nagy
                            csipet sót. A lángot vedd közepesen magasra, és addig pirítsd, amíg a gomba összes leve
                            teljesen
                            el nem párolgott.
                        </p>
                        <p>
                            Vedd a lángot közepesre, add hozzá a fokhagymát, a kakukkfüvet és a rozmaringot, és pirítsd
                            2-3
                            percig
                        </p>
                        <p>
                            Most jön a sűrített paradicsom: ha hozzáadtad, ezt is pirítsd két-három percig (ez mindig
                            fontos
                            lépés a sűrített paradicsomnál — így lesz a végeredmény a legízletesebb), majd öntsd hozzá a
                            vörösbort, és 2-3 percig kevergesd.
                        </p>
                        <p>
                            Tedd a serpenyőbe a felkockázott répát, a zöldborsót, a babérlevelet és a paprikát, majd
                            öntsd
                            hozzá a zöldségalaplevet.
                        </p>
                        <p>
                            Add hozzá a lencsét és vedd a lángot magasra. Ha felforrt, vedd vissza a lángot, hogy épp
                            csak
                            rotyogjon.
                        </p>
                        <p>
                            Miközben a lencse fő, elkészítjük a tölteléket: a krumplit hámozd meg, kockázd fel, és főzd
                            puhára. A sütőt kb. mostanában érdemes előmelegíteni 200 fokra.
                        </p>
                        <p>
                            Ha a krumpli megpuhult, öntsd le róla a főzővizet, krumplinyomóval törd össze, majd keverj
                            hozzá
                            négy evőkanál sörélesztőpelyhet, sót (ne spórold ki belőle — legyen jó sós, sütés után
                            ugyanis
                            enyhébb íze lesz), frissen őrölt borsot, és annyi jó minőségű margarint vagy olívaolajat,
                            amennyitől szép krémes lesz (kb. 4-5 evőkanál). Nem baj, ha nem töröd teljesen simára, de ne
                            maradjanak benne túl nagy darabok sem.
                        </p>
                        <p>
                            A lencse kb. 25-30 perc alatt puhára fő (a tölteléknek pedig jó sűrűnek kell lennie — ha még
                            nem
                            az, akkor főzd tovább): ha ez megtörtént, halászd ki belőle a babérleveleket, és adj hozzá
                            két-két evőkanál szójaszószt és sörélesztőpelyhet, valamint frissen őrölt borsot és ízlés
                            szerint sót.
                        </p>
                        <p>
                            A tölteléket egy közepesen nagy tepsiben simítsd el, majd kanalazd rá a krumplit, és egy
                            kanállal igazgasd szép simára. Villával megmintázhatod, ha szeretnéd.
                        </p>
                        <p>
                            Kb. 20-25 perc alatt készre sül. Fontos, hogy mielőtt felvágod, hagyd legalább 20 percig a
                            tepsiben, különben nagyon szét fog esni.
                        </p>
                    </div>
                </div>
            </>
    },
    {
        id: 3,
        title: "8D zene - Imagine Dragnos - Believer",
        content:
            <>
                <h2 className={'md:text-4xl'}>Próbáltad már a 8D zenét? Itt az idő, használd a fülhallgatód.</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/CepeycyGhwE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/CepeycyGhwE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
            </>

    },
    {
        id: 4,
        title: "Mikulás játék  - csomag kézbesítés",
        content:
            <>
                <Game game={'tronix/index.html'} title={"Tronix Játék"}
                      description={""}/>
            </>
    },
    {
        id: 5,
        title: "Kép - Even the best weeks start with Monday",
        content: <>
            <h2>5. nap</h2>
            <div>
                <img src="assets/bayer/Monday-Motivational-Quotes.png" alt="" className={'my-4 rounded-lg'}/>
            </div>
        </>
    },
    {
        id: 6,
        title: "5 magyar kézműves workshop amit érdemes kipróbálni, nem csak az ünnepi időszakban",
        content: <>
            <h2 className={'md:text-4xl'}>5 magyar kézműves workshop amit érdemes kipróbálni, nem csak az ünnepi
                időszakban</h2>
            <div className={'mt-6 mb-4'}>
                <div className={'flex flex-col md:flex-row md:items-center justify-between mb-4'}>
                    <div>
                        <h4 className={'mb-2 text-3xl'}>1. Fankapanka</h4>
                        <p>Hímző workshop, Fankapanka style-ban.</p></div>
                    <div className={'flex flex-col items-end'}>
                        <a href="https://fankapanka.com/product-category/workshops/" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Megnézem</a>
                    </div>
                </div>
                <div className={'flex flex-col md:flex-row md:items-center justify-between mb-4'}>
                    <div>
                        <h4 className={'mb-2 text-3xl'}>2. Teo studio</h4>
                        <p>Tuft it yourself, csináld magad!</p></div>
                    <div className={'flex flex-col items-end'}>
                        <a href="https://www.teostudioo.com/hu/book-online" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Megnézem</a>
                    </div>
                </div>
                <div className={'flex flex-col md:flex-row md:items-center justify-between mb-4'}>
                    <div>
                        <h4 className={'mb-2 text-3xl'}>3. Madebyyou</h4>
                        <p>Üvegékszer, kerámia festés… a tökéletes kikapcsolódáshoz.</p></div>
                    <div className={'flex flex-col items-end'}>
                        <a href="https://www.madebyyou.hu/termekkategoria/workshopok/" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Megnézem</a>
                    </div>
                </div>
                <div className={'flex flex-col md:flex-row md:items-center justify-between mb-4'}>
                    <div>
                        <h4 className={'mb-2 text-3xl'}>4. Bellavilla</h4>
                        <p>Az akvarellek világában barongolunk.</p></div>
                    <div className={'flex flex-col items-end'}>
                        <a href="https://bellavilla.hu/collections/workshop" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Megnézem</a>
                    </div>
                </div>
                <div className={'flex flex-col md:flex-row md:items-center justify-between'}>
                    <div><h4 className={'mb-2 text-3xl'}>5. Fessneki</h4>
                        <p>Gyerünk, Ess neki!</p></div>
                    <div className={'flex flex-col justify-center items-end'}>
                        <a href="https://fessneki.hu/#actualities" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Megnézem</a>
                    </div>

                </div>
            </div>
        </>
    },
    {
        id: 7,
        title: "Kép - You have such a pretty face, you should be on a christmas card",
        content: <>
            <h2>7. nap </h2>
            <div>
                <img src="assets/bayer/dec7.jpg" alt="" className={'my-5 mx-auto rounded-lg'}/>
            </div>
        </>
    },
    {
        id: 8,
        title: "Karácsonyi adományozás - Hintalovon",
        content: <>
            <h2>Karácsonyi adományozás</h2>
            <div><img src="assets/bayer/kephintalo.png" alt="Karácsonyi Adományozás"/></div>
            <div className={'flex justify-center'}>
                <a href="https://hintalovon.hu/adomanybolt/ "
                   className={'btn-primary w-fit mt-4 md:px-32 px-8 py-1'}>Adományozok</a></div>
        </>
    },
    {
        id: 9,
        title: "Magyarország kedvence: a töltött káposzta",
        content: <>
            <h2> Magyarország kedvence: a töltött káposzta </h2>
            <div>
                <img src="assets/bayer/img_7443.jpg" alt="" className={'my-4 rounded-lg'}/>
            </div>
            <h4>Magyarországon a karácsony, de igazából az egész téli szezon megkerülhetetlen nagyágyúja a töltött
                káposzta. Nem éppen a szuperlazán, 20 perc alatt összedobható ételek közé tartozik, de a végeredmény
                önmagáért beszél.</h4>
            <div className="grid md:grid-cols-4 gap-x-2">
                <div>
                    <h3>Hozzávalók</h3>
                    <h4 className={'mb-2'}>Az aprókáposzta alaphoz</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>1.8 kg savanyú káposzta</li>
                        <li>2 nagy ek sertés zsír</li>
                        <li>4 fej közepes vöröshagyma</li>
                        <li>8 közepes gerezd fokhagyma</li>
                        <li>1 nagy ek édesnemes fűszerpaprika</li>
                        <li>1 mk csípős fűszerpaprika</li>
                        <li>½ mk őrölt bors</li>
                        <li>25 dkg sertésdagadó</li>
                        <li>15 dkg hasaalja szalonna</li>
                        <li>10 dkg füstölt kolbász</li>
                        <li>35 dkg füstölt oldalas</li>
                    </ul>
                    <h4 className={'mb-2'}>A töltelékhez</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>25 dkg hasaalja szalonna</li>
                        <li>50 dkg sertésdagadó</li>
                        <li>2 tk zsír</li>
                        <li>1 fej kisebb vöröshagyma</li>
                        <li>8 közepes gerezd fokhagyma</li>
                        <li>30 dkg rizs</li>
                        <li>½ l víz</li>
                        <li>2 egész tojás</li>
                        <li>1 ek édesnemes fűszerpaprika</li>
                        <li>1 tk csípős fűszerpaprika</li>
                        <li>őrölt bors</li>
                        <li>só</li>
                    </ul>
                    <h4 className={'mb-2'}>A rántáshoz</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>1 nagy ek sertés zsír</li>
                        <li>1 púpozott ek liszt</li>
                        <li>1 púpozott tk édesnemes fűszerpaprika</li>
                        <li>1 ½ dl hideg víz</li>
                        <li>2 dl tejföl</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <h4>A töltelékhez:</h4>
                    <p>A finomra vágott vöröshagymát egy lábasban a forró zsíron üvegesre párolunk, hozzáadjuk a zúzott
                        fokhagymát, lehúzzuk a tűzről, rádobjuk a fűszerpaprikát és rögtön felöntjük 1,5 dl vízzel.
                        Sózzuk, borsozzuk és hozzáadjuk a nagyobb darabokra vágott dagadót, lebőrözött és felkockázott
                        hasaalja szalonnát, a szintén nagyobbra darabolt füstölt oldalast, a vastag karikákra vágott
                        füstölt kolbászt és a hasaalja szalonna bőrét is. Alacsony lángon kb. 1,5 órát főzzük és közben
                        elkészítjük a tölteléket:
                    </p>
                    <p>
                        Egy lábasban felforraljuk a vizet egy teáskanál zsírral és két teáskanál sóval, hozzáöntjük a
                        rizst és 2-3 percig főzzük, majd fedő alatt, 120 fokos sütőben 20 perc alatt 80%-osra pároljuk.
                        Ha kész, a rizst egy tepsin szétterítjük, hogy minél előbb kihűljön. Közben a tölteléknek szánt
                        dagadót és bőr nélküli hasaalja szalonnát kockára vágjuk és ledaráljuk. A vöröshagymát és a
                        zúzott fokhagymát egy teáskanál zsíron aranyszínűre pirítjuk és félretesszük. Összekeverjük a
                        hozzávalókat: a darált húst és szalonnát, a párolt rizst, a tojásokat és a lepirított
                        hagymát-fokhagymát. A masszát sóval, borssal, fűszerpaprikával és ízlés szerint csípős
                        pirospaprikával ízesítjük. A begyúrt tölteléket rövid időre hűtőbe tesszük, majd 18 db egyforma
                        gombócot formázunk belőle.
                    </p>
                    <h4>
                        A töltés:
                    </h4>
                    <p>
                        Elkészítjük a töltött káposztákat: a levelek torzsájának kiálló zömök részét egy késsel lapjában
                        levágjuk, hogy minél vékonyabb legyen. Ha nagyok a káposztalevelek, akkor a szélüket és a
                        tetejüket is lenyisszantjuk. A megformázott, szépen kiterített levél aljára helyezünk egy kissé
                        hengeresre formázott gombócot, majd alulról jó szorosan feltekerjük és a két oldalát szintén
                        szorosan begyömöszöljük, hogy egy szép, zárt, feszes töltött levelet kapjunk. Ezt megismételjük
                        az összes gombóccal.
                    </p>
                    <p>
                        Ha a kolbászos-dagadós-oldalasos pörköltünk elkészült, akkor rápakoljuk az ízlés szerint
                        átöblített, vagy savanyún hagyott aprókáposzta 80%-át (nem is kell átkavarni) és ezen a káposzta
                        ágyon szorosan elhelyezzük két rétegben a betöltött káposztákat. A tetejükre rakjuk a megmaradt
                        aprókáposztát, az egészre öntünk 2 dl vizet és lefedve alacsony lángon 1,5 órát pároljuk. A hő
                        hatására a pörköltes gőz szépen átjárja majd az egész káposztát és elkészíti a levelekben lévő
                        tölteléket is.
                    </p>
                    <p>
                        Ha letelt a párolásra szánt 1,5 óra, befejezésképp rántást (igazából egy rántásos habarást)
                        készítünk: kevés zsíron zsemleszínig hevítjük a lisztet, lehúzzuk a tűzről, hozzáadjuk a
                        pirospaprikát, elkeverjük és felöntjük hideg vízzel. Belekeverjük a tejfölt és az egészet
                        hozzáöntjük a kész aprókáposztás alaphoz úgy, hogy előtte a töltött káposztákat kiszedtük a
                        fazékból. Az aprókáposztát jól összekeverjük a habarással, további öt percig rotyogtatjuk és
                        kész is vagyunk. Én a töltött-, és az aprókáposztát így külön tárolom és csak tálalásnál rakom
                        őket egybe, igény szerinti arányban. Nálam a két töltelék, sok apró és sok kiszemezgetett húsos
                        rész a nyerő, nagy adag tejföllel a tetején.
                    </p>
                </div>
            </div>
        </>
    },
    {
        id: 10,
        title: "Zene - Mariah Carey - All I Want For Christmas Is You (Official Video)",
        content: <>
            <h2>Hangolódj az Ünnepekre!</h2>
            <div className={'md:flex justify-center my-4 hidden'}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/yXQViqx6GMY"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/yXQViqx6GMY"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
        </>
    },
    {
        id: 12,
        title: "Vicces kép - Hány korszakból áll egy férfi élete?",
        content: <>
            <h2 className={'text-center mb-5'}>Hány korszakból áll egy férfi élete?</h2>
            <img src="assets/bayer/109444.640.jpg" alt="" className={'mx-auto rounded-lg'}/>

        </>
    },
    {
        id: 14,
        title: "Édes karácsony – Desszertek és csokoládék ajándékba",
        content:
            <>
                <h2>Édes karácsony – Desszertek és csokoládék ajándékba</h2>
                <h4 className={'text-justify'}>A hideg idő beköszöntével, a közeledő karácsony idején ki ne
                    válna egyre torkosabbá? Ráadásul a
                    csodásan dobozolt bonbonok, a pici ékszereknek is beillő csokik, sütik és édességek nemcsak
                    ínycsiklandóak, hanem a szemünket is elkápráztatják. Ne hagyjuk az utolsó pillanatra a bevásárlást,
                    és kényeztessük szeretteinket és magunkat a karácsonyok elmaradhatatlan kísérőjével, egészséges vagy
                    éppen hedonista finomságokkal. </h4>
                <div className={'text-justify mb-6'}>
                    <h3>Chez Dodo macaron manufaktúra</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/chez-dodo.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Szalai Dóri, aki a könnyű és változatos macaron készítésének titkait egyenesen Franciaországban
                        leste el, párizsi sikkel és finomságokkal töltött kicsike, Sas utcai boltjában ismét nem
                        hazudtolja meg magát. Az ünnepekre készülve különleges ízekben és a télhez passzoló színekben
                        vásárolhatjuk meg nála az ajándékba szánt macaronokat, amelyekhez nagyon bájos, fenyőfás
                        csomagolás is tartozik. Ha tehát nemcsak a szájban robbanó ízekkel, hanem a csodás külcsínnel is
                        megörvendeztetnénk magunkat, akkor itt a helyünk. Cím: 1051 Budapest, Sas utca 7.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Ghraoui Chocolate</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/ghraoui.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Az egyik legpatinásabb szír kereskedőcsalád, a Ghraoui a polgárháború miatt menekítette 1805 óta
                        létező csokiüzletét Magyarországra. A nagy múltú vállalkozás fordulatos történetéről itt írtunk
                        korábban. A csokoládéreceptek és a gyümölcsök tartósításának technológiája generációról
                        generációra öröklődik, a különleges tudás egyik legékesebb bizonyítéka, hogy a Ghraoui volt az
                        idei, a 75. cannes-i filmfesztivál hivatalos ajándékozó partnere. Az eseményre szánt 200 éves
                        családi recept alapján készülő, aleppói pörkölt pisztáciával töltött kandírozott
                        sárgabarackdesszertet mi is megkóstolhattuk. A karácsonyra egyébként nemcsak a szokott
                        finomságokkal, hanem különleges téli ízekkel, csomagolással és desszertekkel is készülnek. Cím:
                        1061 Budapest, Andrássy út 31.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Mon Petit Dessert Boutique</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/221232.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Sámson Zsófia cukrászmester webshopjában minden évben nagy hangsúlyt fektet az ünnepi
                        kollekcióra. Néhány hete nyitotta meg üzletét a nemrég Michelin-csillagot szerzett Rumour
                        étterem előterében, így a kiszállítás mellett már helyben is élvezhetjük francia alapanyagokból
                        készített különlegességeit. Idén torta, sütemény, bonbon, a frissességét több nap elteltével is
                        megőrző, látványos travel cake és zserbó is elérhető lesz a kínálatban, mellettük pedig érdemes
                        kipróbálni az otthon süthető aranygaluskát, ami méltán lett közönségkedvenc. Cím:
                        1052 Budapest, Petőfi tér 3-5.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Rózsavölgyi Csokoládé</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/rozsavolgyi.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>A több rangos nemzetközi elismeréssel díjazott vállalkozás mögött egy házaspár, Csiszár Katalin
                        és Szabad Zsolt állnak, akik több mint húsz éve készítik különleges csokoládéikat. Az édességek
                        venezuelai és madagaszkári kakaóbabból készülnek, amihez csak egy kevés kakaóvaj és organikus
                        barna cukor kerül, így olyan édességet kóstolhatunk, amelyben a valódi ízek, nem pedig a cukor
                        dominál. Az ünnepekre fehér és fekete csokival töltött Mikulás-tasakkal, karácsonyi forró
                        csokival és alkoholos bonbonokkal is készülnek. Cím:
                        1053 Budapest, Királyi Pál utca 6.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Galffis Chocolates</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/galffis.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>A trópusi esőerdők világát idéző hangulattal, helyszínen pörkölt kávéval és saját csokikkal nyílt
                        meg nemrégiben a Batthyány téren a Galffis Chocolates. A frissítő kávékülönlegességek és
                        szendvicsek mellett a csoki a főszereplő, amely Gálffi Dezső manufaktúrájából érkezik,
                        gyermekekhez és gyermeklelkű felnőttekhez egyenesen a pult mögötti kisvonat segítségével.
                        Nemcsak akkor érdemes betérnünk ide, ha reggelizni szeretnénk vagy mi magunk vágyunk egy kis
                        édességre, hanem akkor is, ha céges ajándékot keresünk, vagy szeretteinket örvendeztetnénk meg
                        egy csomag elegáns dobozban érkező, igazán finom csokival. A sokféle nyalánkságot látva az
                        ételallergiával vagy súlyukkal küzdőknek sem kell lemondóan sóhajtaniuk, hiszen ők is találnak
                        majd kedvükre valót a vegán, a laktóz- vagy a cukormentes finomságok között. Cím:
                        1011 Budapest, Batthyány tér 2.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Four Seasons Hotel - Gresham Palota</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/four-seasons.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Budapest egyik legszebb szecessziós műemléke és a benne működő Four Seasons Hotel – Gresham
                        Palota minden évszakban újabb és újabb különleges élménnyel várja nemcsak a turistákat, hanem a
                        budapestieket is. Webshopjukat nemrégiben indították el, azóta pedig csodás tortákat és gyönyörű
                        süteményeket is rendelhetünk tőlük. Nincs ez másképp az ünnepek alkalmával sem, karácsonyra
                        könnyű choux-val, ropogós mille-feuille-jel, birsalmás, sütőtökös és csokis ízekkel édesítik meg
                        számunkra az ünnepet. Cím:
                        1051 Budapest, Széchenyi István tér 5-6.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Chouchou</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/chouchou.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Szász Kinga Újlipótváros napjait édesíti meg üzletében, webshopjában pedig mindannyian
                        hozzájuthatunk finom tortáihoz, csokijaihoz. Az ünnepekre házias külsejű pekándiós pitéje
                        mellett mentes tortáit is ajánljuk. Modern külsejű bonbonválogatás, lágy trüffelek, forró csoki
                        és táblás csokoládék is várják a finomságokra fogékony vásárlókat karácsonykor is. Cím:
                        1136 Budapest, Hegedűs Gyula utca 23.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Cadeau Bonbon Manufaktúra</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/cadeau.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Balogh László cukrászmester még a 90-es években, Gyulán indította el saját cukrászdáját, amellyel
                        a helyi hagyományok megőrzése is célja volt. A vállalkozás később nemzetközi elismeréseket is
                        szerzett, néhány éve pedig már a Veres Pálné utcában is megkóstolhatjuk finom bonbonjait. Az
                        ünnepekre díszdobozos válogatásokat állíthatunk össze nála szeretteinknek, vásárolhatunk saját
                        készítésű szaloncukrot, és a Veres Pálné utcába biztosan eljön a csokimikulás is. Az üzlet
                        mellett pedig webshopjukba is érdemes benézni. Cím:
                        1053 Budapest, Veres Pálné utca 8.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>MyChoccy Csokoládémanufaktúra</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/mychoccy.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>A lelkes apa-lánya páros bonbonjai színes, ízes kis bontógolyóként fúródnak annak az általános
                        nézetnek az alapjába, hogy a csoki szükségszerűen barna vagy maximum fehér lehet. Kicsi, színes
                        gyöngyökre emlékeztető bonbonjaik elképesztően fel tudják dobni a téli, szürke napok hangulatát,
                        trüffeleikbe pedig pipettából egy kis extra íz is kerülhet. Termékeiket webshopjukban is
                        megvásárolhatjuk. Cím:
                        1092 Budapest, Ráday utca 40.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Zangio Csoki</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/zangio.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>A Zangio szintén családi vállalkozás, az elnevezés a tagok nevének kezdőbetűiből áll össze.
                        Zoltán, Angi és Olga azon munkálkodnak, hogy csoki segítségével minél boldogabbá tegyenek
                        bennünket. A finom, kicsit rusztikus külsejű bonbonok közül érdemes kipróbálni a birsalmásat, de
                        fügés és karamellás változatok is vannak. Az édesség céges ajándéknak és magáncélra is igazán
                        örömteli meglepetés lehet. Cím:
                        1077 Budapest, Wesselényi utca 6.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>SWEETic</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/sweetic.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>A harcos csokiaktivisták boltja ismét olyan hely, ahol elfelejthetjük, hogy a csoki csak barna
                        szokott lenni, vidám, különböző formájú, színes bonbonjaik nemcsak a szemünket ingerlik, hanem
                        nagyon finomak is. Ha nem szeretnénk az üzletbe személyesen ellátogatni, akkor még időben
                        nézzünk be limitált karácsonyi válogatásukért a webshopjukba. Cím:
                        2310 Szigetszentmiklós, Szent Miklós útja 7/B.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Bonbonier</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/bonbonier.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>A Bonboniernél kiváló kézműves bonbonokat próbálhatunk ki, amelyekben gyakran egy-egy igazán
                        finom magyar ital ízét is felfedezhetjük. Így a csinos dobozkába zárt bonbonok nemcsak saját
                        családunk számára lehet kiváló ajándék, de akkor is, ha külföldről várunk vendégeket az
                        ünnepekre. Cím:
                        1092 Budapest, Ráday utca 25–27.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Coco7 Csokoládé Bolt és Látványműhely</h3>
                    <div className={'float-right ml-3'}><img src="./assets/bayer/coco7.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>A városban már többfelé is megkóstolhatjuk a Coco7 franciás ihletésű csokijait és bonbonjait,
                        igazi ünnepi különlegességként a bonbonválogatások mellett akár adventi naptárt is
                        vásárolhatunk, rendelhetünk tőlük. Cím:
                        1015 Budapest, Hattyú utca 1.</p>
                </div>

            </>
    },
    {
        id: 15,
        title: "Karácsonyfadísz ötletek újrahasznosítással",
        content:
            <>
                <h2>
                    Karácsonyfadísz ötletek újrahasznosítással</h2>
                <img src="./assets/bayer/recycle.png" alt="recycle" className={'my-4 mx-auto rounded-lg'}/>
                <h4 className={'text-center'}>Az újrahasznosításban az a szép, hogy még egy ilyen ünnepi tárgy is, mint
                    a karácsonyfadísz is készülhet a leghétköznapibb holmiból, akár hulladékból is.</h4>
                <p>Emlékszem, hogy mennyire ledöbbentem még október végén, amikor betérve egy nagy áruházba, már a
                    polcok fele karácsonyi termékekkel, díszekkel volt megpakolva. És persze buzgón válogattak köztük az
                    emberek. Én utoljára friss házasként, az első karácsonyfánkra vettem újonnan díszt. Azt is
                    viszonylag hamar megbántam, mert elég gyatra minőség volt, pedig nem annak tűnt elsőre. Azóta akadt
                    a kezembe pár karácsonyfadísz turiban és mellettük leginkább sajátkészítésűek ékesítik a fánkat. A
                    következő fokozat az lesz, hogy ezek is leginkább újrahasznosítással készüljenek, ehhez szedtem
                    össze ötleteket ezen a Pinterest táblán!</p>
                <div className={'flex justify-center'}>
                    <a href="https://hu.pinterest.com/zldnek/kar%C3%A1csonyfad%C3%ADszek-%C3%BAjrahasznos%C3%ADt%C3%A1ssal/"
                       target="_blank"
                       className="btn-primary w-fit px-5 px-2">Megnézem</a></div>
            </>
    },
    {
        id: 17,
        title: "Zene - Michael Bublé - It's Beginning To Look A Lot Like Christmas",
        content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/QJ5DOWPGxwg"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/QJ5DOWPGxwg"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>

    },
    {
        id: 18,
        title: "Könyvajánló az ünnepre - Hóesés Notting Hillben",
        content:
            <>
                <h2>Könyvajánló az ünnepre</h2>
                <div>
                    <img src="./assets/bayer/konyv.jpg" alt="Konyv" className={'my-4 mx-auto rounded-lg'}/>
                </div>
                <h4>Szórakoztató és szívmelengető karácsonyi románc. Tökéletes olvasmány mindazoknak, akik szeretik a
                    Hugh Grant-féle romantikus vígjátékokat.</h4>
            </>
    },
    {
        id: 19,
        title: "Mákos bejgli",
        content:
            <>
                <h2> Mákos bejgli</h2>
                <div>
                    <img src="assets/bayer/bejgli.jpg" alt="" className={'my-4 rounded-lg'}/>
                </div>
                <h4>Mákos bejgli nélkül nincs karácsony egy diótagadó embernek sem. Próbáljátok ki, és legyen
                    mákotok!</h4>
                <div className="grid md:grid-cols-4 gap-x-2">
                    <div>
                        <h3>Hozzávalók</h3>
                        <h4 className={'mb-2'}>A tésztához</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>250 g liszt</li>
                            <li>25 g porcukor</li>
                            <li>1 csipet só</li>
                            <li>60 g vaj</li>
                            <li>50 g sertészsír</li>
                            <li>4 g friss élesztő</li>
                            <li>50 ml tej</li>
                            <li>2 db tojássárgája</li>
                        </ul>
                        <h4 className={'mb-2'}>A töltelékhez</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>250 g darált mák</li>
                            <li>12 g vaníliás cukor</li>
                            <li>1 tk őrölt fahéj</li>
                            <li>1 db citrom reszelt héja</li>
                            <li>1 db narancs leve és reszelt héja</li>
                            <li>20 g mazsola</li>
                            <li>20 g kandírozott narancshéj</li>
                        </ul>
                        <h4 className={'mb-2'}>A töltelék leforrázásához</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>80 ml tej</li>
                            <li>100 g cukor</li>
                            <li>50 g méz</li>
                        </ul>
                        <h4 className={'mb-2'}>A kenéshez</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>1 db tojás</li>
                            <li>2 db tojásfehérja</li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>A mákos bejgli tésztájának elkészítéséhez a lisztet összekeverjük a porcukorral és a sóval,
                            majd
                            elmorzsoljuk benne a vajat és a zsírt. A cél az, hogy morzsalékos állagot kapjunk. Az
                            élesztőt
                            elmorzsoljuk a tejben, belekeverjük a tojássárgáját (a fehérjét ne dobjuk el, jó lesz a
                            kenéshez), majd a lisztes keverékhez adjuk, és összedolgozzuk. Nem kell vele túl sokat
                            foglalkozni, hamar egynemű lesz. A tésztát kettéválasztjuk – mérleggel biztosabb a dolog, mi
                            2 x
                            240 grammosra osztottuk. A két tésztaadagot gömbölyűre formázzuk, és frissentartó fóliával
                            lefedve a hűtőbe tesszük egy órára.
                        </p>
                        <p>
                            A töltelékhez összekeverjük a mákot, a vaníliás cukrot, a fahéjat, a citrom- és
                            narancshéjat, és
                            hozzáadjuk az apróra vágott mazsolát is, amit így még azok is el tudnak fogadni, akik nem
                            szeretik. Belekeverjük a kandírozott narancshéjat is – amit ha nem tudtok beszerezni,
                            nyugodtan
                            elhagyhatjátok.
                        </p>
                        <p>
                            A tejet egy kis lábasba öntjük, majd a cukorral, a mézzel és a narancs kifacsart levével
                            együtt
                            felforraljuk, ráöntjük a mákos töltelékre, és összekeverjük. A cél, hogy gyurmázható állagot
                            kapjunk. A masszát lefedjük és 30 percre hűtőbe tesszük.
                        </p>
                        <p>
                            A tésztát kivesszük a hűtőből, és két, kb. 30 cm széles, 20 cm hosszú téglalapot nyújtunk
                            belőle.
                        </p>
                        <p>
                            A tölteléket is kivesszük a hűtőből, elfelezzük, és két frissentartó fólia között két, kb.
                            28 x
                            18 cm-es téglalapot formázunk belőle. Lehúzzuk róluk az egyik fóliát, és fejjel lefelé a két
                            tészta közepére borítjuk. A tészták kétoldali széleit behajtogatjuk a töltelék magasságáig,
                            és
                            feltekerjük. Egy villával mélyen megszurkáljuk, majd megkenjük a felületüket egy enyhén
                            felvert
                            tojással. Hűvös helyre tesszük a rudakat, és hagyjuk, hogy a tojásréteg rászáradjon. Ezután
                            a
                            megmaradt és félretett fehérjét is kicsit felkeverjük, és megkenjük vele a bejglik tetejét,
                            majd
                            újra hagyjuk, hogy rászáradjon. Ez a mozzanat teszi márványossá a bejgli felszínét sütés
                            közben.
                        </p>
                        <p>
                            A rudakat 200 fokra előmelegített sütőbe tesszük 15 percre. Az idő letelte után forgatunk
                            egyet
                            a tepsin, és további 10-15 percre visszatesszük, de csak 185 fokon. Felvágás előtt legalább
                            2-3
                            órát hagyjuk hűlni, de akkor az igazi, ha csak másnap vágjuk meg.
                        </p>
                    </div>
                </div>
            </>
    },
    {
        id: 20,
        title: "Top100: kihagyhatatlan karácsonyi filmek decemberre",
        content:
            <>
                <h2 className={'md:text-4xl'}>Top100: kihagyhatatlan karácsonyi filmek decemberre</h2>
                <h4>Válassz a listában szerepló filmek közül, és hagyd, hogy magával ragadjon a karácsonyi
                    hangulat!</h4>
                <div className="grid ">
                    <div>
                        <div className="col-xs-12">


                            <p>1.<strong> Grincs </strong>– Jim Carrey, Kik, karácsony</p>

                            <p>2. <strong>Reszkessetek betörők</strong> – nincs karácsony
                                Kevin nélkül</p>

                            <p>3.<strong> Reszkessetek betörők 2</strong>. - továbbra sincs
                                karácsony Kevin nélkül</p>

                            <p>4. <strong>Reszkessetek betörők 3.</strong> - van karácsony
                                Kevin nélkül, de minek?!</p>

                            <p>5. <strong>Let It Snow</strong> - szabadnapos sztár és a
                                tipikus lány a szomszédból találkozása a Netflixen</p>

                            <p>6.&nbsp;<strong>Holiday</strong> – Amerika, Anglia, szerelem,
                                karácsony</p>

                            <p>7. <strong>Hull a pelyhes</strong> – Turbo Man, Arnold
                                Schwarzenegger, karácsonyi őrület</p>

                            <p>8. <strong>Igazából szerelem</strong> – kétségtelenül a
                                valaha volt legjobb karácsonyi film</p>

                            <p>9. <b>Last Christmas -&nbsp;</b>a sárkányok anyja,&nbsp;a
                                Crazy Rich Asians főszereplője, London és persze karácsony</p>
                            <p>10. <strong>Tapló télapó</strong>- a Mikulás bácsi bunkó,
                                faragatlan kiadása</p>

                            <p>11. <strong>A Szépség és a Szörnyeteg-Varázslatos
                                karácsony</strong> - bizony, amíg Belle a kastélyban raboskodott, volt karácsony is</p>

                            <p>12. <strong>Négy karácsony</strong> – nincs karácsony család
                                nélkül, főleg, ha négy van belőle</p>

                            <p>13. <strong>Szerelem a végzeten</strong> – New York, hóesés,
                                szerelem</p>

                            <p>14. <strong>Télapu</strong> – ennél cukibb karácsonyi film
                                aligha létezik</p>

                            <p>15. <strong>Télapu 2.</strong> - a Mikulás feleséget keres
                            </p>

                            <p>16. <strong>Télapu 3.</strong> - már megint meg kell menteni
                                a karácsonyt</p>

                            <p>17. <strong>Karácsonyi rémek</strong> – karácsonyi ének
                                kicsit másként</p>

                            <p>18. <strong>Nicsak, ki beszél most!</strong> - beszélő
                                kutyák, gyerekek, karácsony</p>

                            <p>19. <strong>Karácsonyi lidércnyomás</strong> – Rosszcsont
                                Jack, Halloween, karácsony</p>

                            <p>20.<strong> The Knight Before Christmas </strong>- időutazás,
                                lovag és persze szerelem karácsonykor</p>

                            <p>21. <strong>Karácsonyi ének </strong>– Charles
                                Dickens&nbsp;klasszikus meséje animálva, Jim Carreyvel</p>

                            <p>22. <strong>Bunyó karácsonyig</strong> – Bud Spencer és
                                Terence Hill karácsonyra</p>

                            <p>23. <strong>Polár expressz</strong> – cuki animációs mese a
                                karácsony szelleméről</p>

                            <p>24. <strong>Hóbarát </strong>– sírni ér</p>

                            <p>25. <strong>Karácsonyi vakáció</strong> – egy igazi
                                kihagyhatatlan klasszikus</p>

                            <p>26. <strong>Szellemes karácsony</strong> – ismét egy afféle
                                karácsonyi ének feldolgozás</p>

                            <p>27. <strong>Kelekótya karácsony</strong> – a karácsony, amit
                                nem lehet kihagyni</p>

                            <p>28. <strong>Karácsony Artúr</strong> – egyetlen gyermek sem
                                maradhat ki a karácsonyból</p>

                            <p>29. <strong>Csoda New York-ban</strong> – a karácsony
                                ünnepének valódi értelme</p>

                            <p>30. <strong>Túlélni a karácsonyt</strong>- bérelt család az
                                ünnepekre</p>

                            <p>31. <strong>Télbratyó</strong> – logikus, hogy a Télapónak
                                van egy nem túl jó fej öccse</p>

                            <p>32. <strong>Kőkemény család </strong>– ünnepi családi dráma
                            </p>

                            <p>33. <strong>Segítség, karácsony</strong> – így ünnepel a
                                lelki segélyszolgálat</p>

                            <p>34.<strong>Huncut karácsony</strong> – elkényeztetett
                                kislány, dada, Plaza Hotel</p>

                            <p>35. <strong>A karácsony története</strong> – szívmelengető
                                családi film</p>

                            <p>36. <strong>Mi a manó? </strong>- Buddy, a két méter magas
                                karácsonyi manó</p>

                            <p>37. <strong>Látástól Mikulásig </strong>– gimis fiú a pácban,
                                úton Kaliforniából New Yorkba</p>

                            <p>38. <strong>Szörnyecskék</strong> – kinek jutna eszébe, de
                                igen, ez is karácsonykor van</p>

                            <p>39. <strong>Ki a Télapó?</strong> - amnéziás télapó az
                                emlékeit keresi</p>

                            <p>40. <strong>S</strong><strong>anta Baby</strong> – bizony,
                                van egy ilyen film is</p>

                            <p>41. <strong>Richie Rich 2.</strong>-A rosszcsont karácsonya –
                                Richie Rich ünnepel</p>

                            <p>42. <strong>Karácsony</strong> – igen, van egy karácsonyi
                                film, aminek egyszerűen csak ez a címe</p>

                            <p>43. <strong>Karácsonyi fények </strong>– művész fiú
                                karácsonyra</p>

                            <p>44. <strong>Mrs. Télapó</strong> – a Télapónak feleséget kell
                                keresni</p>

                            <p>45. <strong>White Christmas</strong> – aki nyomasztó
                                hangulatra vágyik, nézze meg a Black Mirror aktuális részét</p>

                            <p>46. <strong>Karácsonyi csók</strong> – sose csókold meg a
                                főnököd pasiját</p>

                            <p>47. <strong>Mikulásné kerestetik</strong> – és ismét
                                feleséget keresnek a Télapónak</p>

                            <p>48. <strong>Rabold el a télapót</strong> – amikor a Télapót
                                betörőnek nézik</p>

                            <p>49. <strong>Kedves Télapó</strong> – bizony, van, aki
                                megválaszolja a Télapónak írt leveleket</p>

                            <p>50. <strong>A pót télapó</strong> – szívtelen nő meglágyul a
                                Télapónak hála</p>

                            <p>51. <strong>Madagaszkarácsony</strong> – a madagaszkári
                                állatok ünnepelnek</p>

                            <p>52. <strong>D</strong><strong>enisz a komisz
                                karácsonya</strong>- éppen neki ne lenne karácsonyi története?</p>

                            <p>53. <strong>Snoopy és a karácsony</strong> – Charlie Brown,
                                Snoopy és az ünnepek</p>

                            <p>54. <strong>Rudolf a rénszarvas</strong>- igen, ez sem csak
                                egy karácsonyi dal</p>

                            <p>55. <strong>Muppetek karácsonyi éneke</strong>- karácsoyi
                                sztori Muppet-módra</p>

                            <p>56.<strong>Az öt lgenda</strong>- Húsvéti Nyuszi, Fogtündér,
                                Mikulás…</p>

                            <p>57. <strong>Barbie és a Diótörő</strong>- Barbie, animáció,
                                balett</p>

                            <p>58. <strong>A karácsonyfa dísze</strong> – a reményt
                                jelképező karácsonyfadísz</p>

                            <p>59. <strong>A karácsonyi szív </strong>- kisfiú,
                                szívátültetés, karácsonyi fények</p>

                            <p>60. <strong>A karácsonyi vakáció –</strong> a Télapónak lánya
                                is van</p>

                            <p>61. <strong>A karácsonymanó meséje – </strong>karácsonyi
                                animációs mese, inkább gyerekeknek</p>

                            <p>62. <strong>Hivatali karácsony</strong> – amikor eldurvul a
                                céges buli</p>

                            <p>63. <strong>Káosz karácsonyra</strong> – tökéletes karácsony
                                lehetne a családdal</p>

                            <p>64.<strong> </strong><strong>Karácsonyi naptárpasik
                                –</strong> montanai szerelmi sokszög</p>

                            <p>65. <strong>Ki segít a mikulásnak? - </strong>Mikulás
                                főmanója, gonosz üzletember, Mikulás</p>

                            <p>66. <strong>Kiskarácsony mindenáron</strong> – karácsonyi
                                hangulat felsőfokon</p>

                            <p>67. <strong>Jégkorszak-Állati nagy karácsony</strong> – pont
                                nekik lenne ne karácsonyuk?</p>

                            <p>68. <strong>Karácsonyi csínytevő</strong>- női tolvaj
                                Cunnecticutban… igen, van köze a karácsonyhoz</p>

                            <p>69.<strong>Aludj csak, én álmodom</strong> – szerelem és
                                dráma karácsonyra</p>

                            <p>70. <strong>Bidget Jones </strong>&nbsp;– van ember a földön,
                                aki el tudja felejteni a szarvasos pulcsit?</p>

                            <p>71. <strong>Karácsonyi kutyabalhé </strong>– Disney mese
                                kutyával</p>

                            <p>72. <strong>Karácsonyi csoda </strong>– családi „akciófilm”
                                az ünnepekre</p>

                            <p>73. <strong>Karácsonyi kívánságok</strong> – most nem kell
                                vigyázni, hogy mit kívánsz</p>

                            <p>74. <strong>Egész évben farsang</strong>- bizony, a White
                                Christmas című sláger innen indult</p>

                            <p>75. <strong>Az élet csodaszép </strong>– kétségbeesett
                                fiatalember és az őrangyala</p>

                            <p>76. <strong>Folytassa karácsonykor </strong>- a Folytassa
                                sorozat karácsonyi kiadása</p>

                            <p>77. <strong>Drágán add az életed</strong> – nincs mit tenni,
                                vétek lenne kihagyni</p>

                            <p>78. <strong>Füst </strong>– kissé szomorú, azonban gyönyörű
                                emberi történetek</p>

                            <p>79. <strong>Twist Olivér </strong>– klasszikus történet
                                karácsonyra</p>

                            <p>80. <strong>Angyali üzlet </strong>– anya, lánya és egy kis
                                színjáték</p>

                            <p>81. <strong>Garfield karácsonya</strong>- persze, hogy neki
                                is van</p>

                            <p>82. <strong>Hupikék Törpikék- Karácsonyi ének</strong> –
                                természetesen nekik is ...</p>

                            <p>83. <strong>Karácsony a kastélyban</strong> – tipikus nem
                                gagyin vicces, hanem szép családi film</p>

                            <p>84. <strong>Volt egyszer egy karácsony</strong> – amikor a
                                Mikulás is besokall a karácsonytól</p>

                            <p>85. <strong>Egy fiú karácsonya</strong> – nagypapa mesél az
                                unokájának</p>

                            <p>86. <strong>Tapsi Hapsi bolondos karácsonyi meséi </strong>–
                                természetesen...</p>

                            <p>87. <strong>A Flinstone család: Kőkorszaki
                                karácsony</strong> – és igen, ők is itt vannak</p>

                            <p>88. <strong>A legszebb karácsonyi ajándék</strong> –
                                időjárásátalakító-gép Los Angelesben</p>

                            <p>89. <strong>Jégvarázs </strong>– minden kis- és nagylány
                                aktuális kedvenc Disney meséje</p>

                            <p>90. <strong>Karácsony előtt </strong>– magyar rövidfilm</p>

                            <p>91. <strong>Karácsonyi történet</strong> – úgy tűnik, hogy
                                légpuskát kérni karácsonyra nem jó ötlet</p>

                            <p>92. <strong>Kétséges karácsony</strong> – a Mikulás álruhában
                            </p>

                            <p>93. <strong>Milliomos Mikulás</strong> – egy éjszaka csodákra
                                képes</p>

                            <p>94. <strong>Szuperkarácsony</strong> – amikor rájössz, hogy a
                                tökéletesség nem a legjobb</p>

                            <p>95. <strong>Régimódi karácsony</strong> – Írország,
                                karácsony, titkok</p>

                            <p>96. <strong>Nagypapát kérek karácsonyra! </strong>- unoka,
                                nagypapa, baleset, karácsony</p>

                            <p>97. <b>Princess Switch -&nbsp;</b>a Netflix és Vanessa
                                Hudgens közös cukisága</p>

                            <p>98. <b>Rossz anyák karácsonya -&nbsp;</b>nem biztos, hogy így
                                képzelted el az ünnepet</p>

                            <p>99. <strong>A diótörő és a négy birodalom</strong> – a
                                diótörő története kicsit újragondolva</p>

                            <p>100. <strong>Családi karácsony</strong> - két ünnep között
                                irány a mozi!</p>
                        </div>
                    </div>
                </div>
            </>
    },
    {
        id: 21,
        title: "Vicces kép - I want a magical unicorn for christmas..",
        content:
            <>
                <h2>21. nap</h2>
                <div>
                    <img src="assets/bayer/Screenshot_1.png" alt="" className={'my-4 mx-auto rounded-lg'}/>
                </div>
            </>
    },
    {
        id: 22,
        title: "Mézeskalácsos trüffelgolyó - tuti karácsonyi ajándék",
        content:
            <>
                <h2>Mézeskalácsos trüffelgolyó
                    - tuti karácsonyi ajándék</h2>
                <div>
                    <img src="assets/bayer/Group 361.png" alt="" className={'my-4'}/>
                </div>
                <h4>Ismét egy szuper karácsonyi ajándékötlet, ami tényleg sokáig eláll a hűtőben. Használjatok jó
                    minőségű
                    étcsokit, sok-sok fűszert és magas kakaótartalmú, holland kakaóport és higgyétek el, hogy isteni
                    finom
                    lesz
                    a végeredmény ;)</h4>
                <div className="grid md:grid-cols-4 ">
                    <div>
                        <h3>Hozzávalók</h3>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>120 ml zsíros tejszín</li>
                            <li>300 g étcsoki</li>
                            <li>100 g puha vaj</li>
                            <li>1 mk őrölt gyömbér</li>
                            <li>1 mk őrölt szegfűszeg</li>
                            <li>2 mk őrölt fahéj</li>
                            <li>1/2 mk őrölt szerecsendió</li>
                            <li>1 db narancs reszelt héja</li>
                            <li>3 ek holland kakaópor</li>
                        </ul>
                    </div>
                    <div className={'col-span-3'}>
                        <p>A gasztroajándékkal sosem lőhettek mellé, így akiknek nincs ötletetek, hogy mit adjatok
                            karácsonyra,
                            egy saját készítésű, ünnepi csomagolású süti mindig jó meglepetés. A mézeskalácsos
                            trüffelgolyóval
                            biztos sikeretek lesz. A kevésbé édesszájú ismerősök is odáig lesznek érte, és az a jó
                            benne,
                            hogy
                            nem kell rengeteg energiát fektetni az elkészítésébe.
                        </p>
                        <p>
                            Az étcsokit leöntjük a felmelegített tejszínnel, belerakjuk a puha vajat, reszelt
                            narancshéjat,
                            és
                            beleszórjuk a fűszereket. Elkeverjük, hogy a vaj és a csoki is feloldódjon a meleg
                            tejszínben.
                        </p>
                        <p>
                            Az alapot bedobjuk a hűtőbe, időnként átkeverjük, és amikor már megdermedt, kézzel kisebb
                            golyókat
                            formálunk a masszából, és meghempergetjük őket a kakaóporban. A karácsonyi asztalon is
                            találhatjuk,
                            de pici zacskókba rakva szuper ajándék lesz, a hűtőben pedig sokáig eláll.
                        </p>
                    </div>
                </div>
            </>
    },
    {
        id: 24,
        title: "Karácsonyi üdvözlet GIF",
        content:
            <>
                <h2>Karácsonyi üdvözlet</h2>
                <div>
                    <img src="assets/bayer/Boldog-karacsonyt.gif" alt="" className={'my-4 mx-auto'}/>
                </div>
            </>
    },
    {
        id: 1,
        title: "Michael Bublé vicces GIF",
        content:
            <>
                <h2>1. nap</h2>
                <div>
                    <img src="assets/crop/Video_2-11-25.gif" alt="Gif" className={''}/></div>
            </>
    },
    {
        id: 2,
        title: "Ragacsos hasselback sütőtök",
        content:
            <>
                <h2>Ragacsos hasselback sütőtök</h2>
                <div>
                    <img src="assets/crop/ragacsos-hasselback-sutotok.jpg" alt="" className={'my-4 rounded'}/>
                </div>
                <h4>Mindenki ismeri a hasselback burgonyát… vagy ha nem, akkor keress rá nálunk, hiszen arra is van
                    receptünk! Ez most egy sütőtökös verzió, ami a karácsonyi asztal egyik legizgalmasabb köreteként fog
                    ragyogni. </h4>
                <div className="grid md:grid-cols-4 ">
                    <div>
                        <h3>Hozzávalók</h3>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>1 db sütőtök</li>
                            <li>2 ek barna cukor</li>
                            <li>2 tk füstölt fűszerpaprika</li>
                            <li>½ db narancs leve</li>
                            <li>20 g dió</li>
                            <li>250 g vaj</li>
                            <li>2 ek juharszirup</li>
                            <li>½ csokor kakukkfű
                            </li>
                            <li>só, bors</li>
                            <li>nagy szemű só
                            </li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>A hasselback sütőtökhöz a sütőtököt megpucoljuk, félbevágjuk és eltávolítjuk a magjait.
                            Mindkét oldalára egy-egy evőpálcikát helyezünk, hogy amikor bevagdossuk a tököt, ne vágjuk
                            végig teljesen. Kb. 5 mm-enként vágjuk be az elejétől a végéig. Ezt megismételjük a másik
                            felével is.
                        </p>
                        <p>
                            Egy lábasban közepes lángon összeolvasztjuk a vajat, a barna cukrot, a juharszirupot, a
                            füstölt paprikát, a friss kakukkfű felét és a narancs levét. Sózzuk, borsozzuk és amikor a
                            cukor teljesen felolvadt, egy tepsit kibélelünk sütőpapírral, ráhelyezzük a bevagdosott
                            tököket, majd megkenegetjük a fűszeres vajjal – de ne használjuk el az összeset, mert sütés
                            közben még kétszer meg fogjuk kenni a hasselback sütőtökünket.
                        </p>
                        <p>
                            180 fokos sütőben 45 perc alatt elkészül a sütőtök, úgy, hogy 20 percenként bekenegetjük a
                            fűszeres vajunkkal.
                        </p>
                        <p>
                            Miután elkészült, megszórjuk aprított dióval, a maradék friss kakukkfűvel és nagy szemű
                            sóval, és már mehet is a karácsonyi asztalra!
                        </p>
                    </div>
                </div>
            </>
    },
    {
        id: 5,
        title: "Zene - Santa Baby (ft. Jane XØ) | Kaskade Christmas",
        content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/jmZeTx4J1Ew"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/jmZeTx4J1Ew"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Fogadd szeretettel ezt a karácsonyi zenét, és éld át az Ünnepek hangulatát.</h4>
            </>
    },
    {
        id: 8,
        title: "Aranyos kép - 100 % Christmas",
        content: <>
            <h2>8. nap</h2>
            <div>
                <img src="assets/crop/box_100_christmas.png" alt="" className={'rounded hidden md:block'}/>
                <img src="assets/crop/box_100_christmas_mobil.png" alt="" className={'rounded md:hidden'}/>
            </div>
        </>
    },
    {
        id: 9,
        title: "Barna sörös marharagu gombával és gesztenyés dödöllével",
        content: <>
            <h2>Barna sörös marharagu gombával és gesztenyés dödöllével</h2>
            <div>
                <img src="assets/crop/barna-soros-marharagu-gombaval-es-gesztenyevel.jpg" alt="" className={'my-4 rounded'}/>
            </div>
            <h4>Ez a recept ötvözi a két, szerintem legszuperebb őszi alapanyagot: a gesztenyét és a gombát, méghozzá
                egy marharagu formájában. Emellett került bele még egy kevés barna sör is, hiszen valljuk be, az nem tud
                elrontani semmit. </h4>
            <div className="grid md:grid-cols-4 gap-x-2">
                <div>
                    <h3>Hozzávalók</h3>
                    <h4 className={'mb-2'}>A raguhoz</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>300 g marhalábszár</li>
                        <li>2 ek sertészsír</li>
                        <li>2 fej vöröshagyma (130 g/fej)</li>
                        <li>só, bors</li>
                        <li>2 tk sűrített paradicsom</li>
                        <li>2 gerezd fokhagyma</li>
                        <li>2 dl barna sör</li>
                        <li>1 tk kakukkfű</li>
                        <li>1 tk majoránna</li>
                        <li>1 tk füstölt paprika</li>
                        <li>1 ek szójaszósz</li>
                        <li>10 g étcsokoládé</li>
                        <li>150 g csiperkegomba</li>
                    </ul>
                    <h4 className={'mb-2'}>A dödölléhez</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>300 g tisztított burgonya</li>
                        <li>só, bors</li>
                        <li>150 g finomliszt (kb.)</li>
                        <li>8 szem főtt gesztenye</li>
                        <li>2 ek sertészsír</li>
                    </ul>
                    <h4 className={'mb-2'}>A tálaláshoz</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>2 ek tejföl</li>
                        <li>4 szem főtt gesztenye</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <p>A sörös marharagu elkészítéséhez a lábszárat 1 x 1 cm-es kockákra vágjuk, majd egy forró, közepes
                        méretű lábasban magas lángon, 1 evőkanál zsíron lepirítjuk. Ha ez megvan, kiszedjük egy tálba,
                        visszavesszük kissé a lángot, félfőre vágjuk a hagymát és mehet is a lábasba a hús helyére, a
                        maradék zsír társaságában. A hagymát sózzuk, majd aranybarnára pirítjuk. Ha kész, rárakjuk a
                        paradicsompürét és átpirítjuk vele, majd mehet vissza rá a hús és a felszeletelt fokhagyma.
                        Ráöntjük a sört, és addig forraljuk vissza, amíg el nem kezd karamellizálódni.
                    </p>
                    <p>
                        Ekkor ráöntjük a szójaszószt, a majoránnát, a kakukkfüvet és a füstölt paprikát, sózzuk és
                        borsozzuk, és ezt követően, mint egy pörköltet, folyamatosan felöntögetve vízzel puhára főzzük a
                        húst.
                    </p>
                    <p>Ha a lábszár már puha, beledobjuk az étcsokit, a gombát mérettől függően negyedeljük vagy
                        felezzük, és ez is mehet hozzá, majd ezután még kb. 10-15 percig főzzük.</p>
                    <h4>
                        A dödölle elkészítése:
                    </h4>
                    <p>
                        A dödölléhez a burgonyát felkockázzuk és egy lábasban felöntjük annyi vízzel, amennyi éppen
                        ellepi, sózzuk, majd puhára főzzük.
                    </p>
                    <h4>A sörös marharagu befejezése és tálalás
                    </h4>
                    <p>
                        Ha megfőtt, a még forró tűzhelyen a vízzel együtt összetörjük, a gesztenyét felvágjuk kis
                        kockákra, hozzáadjuk a liszttel együtt a krumplihoz, és sózzuk, borsozzuk. A liszt mennyiségét
                        sok minden befolyásolhatja, apránként adagoljuk hozzá – a lényeg, hogy egy kissé ragacsos, de
                        formázható tésztát kapjunk. Amikor homogén tésztát kaptunk, lehúzzuk a tűzről.
                    </p>
                    <p>
                        Két kanál segítségével nagyobb galuskákat szaggatunk a tésztából és magas lángon, egy nagy
                        serpenyőben aranybarnára sütjük őket a sertészsíron.
                    </p>
                    <p>Tányérra szedünk a raguból, mellérakjuk a dödölléket, kanalazunk rá a tejfölből és ráreszelünk
                        néhány gesztenyét is.</p>
                </div>
            </div>
        </>
    },
    {
        id: 11,
        title: "Vizigyöngyös úszógyertya készítés videó",
        content: <>
            <h2>Vizigyöngyös úszógyertya készítés</h2>
            <div className={'md:flex justify-center my-4 hidden'}>
                <iframe width="560" height="600" src="https://www.youtube.com/embed/1REiHCePsMw"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <iframe width="100%" height="600" src="https://www.youtube.com/embed/1REiHCePsMw"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        </>
    },
    {
        id: 13,
        title: "8D zene - Arianda Grande  - Santa Tell me",
        content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/4y7GSbyJu6E"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/4y7GSbyJu6E"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    },
    {
        id: 14,
        title: "Santa Claus Village élő közvetítés videó",
        content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Cp4RRAEgpeU"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/Cp4RRAEgpeU"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Tekints meg mi történik épp most, a Santa Claus village-ben.</h4>
            </>
    },
    {
        id: 15,
        title: "Karácsonyi ajándék csomagolás ötletek – 11 remek tipp kreatív és egyedi ajándékcsomagoláshoz",
        content:
            <>
                <h2 className={'mb-6'}>Karácsonyi ajándék csomagolás ötletek – 11 remek tipp kreatív és egyedi
                    ajándékcsomagoláshoz</h2>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 py-6 border-y-2 '}>
                    <div className={'order-1'}><img src="./assets/crop/csomagolas-1.jpg"
                                                    alt=""
                                                    className={'h-auto max-w-[50%] mx-auto rounded'}/></div>
                    <p className={'order-2 text-lg'}>A karácsonyi mintájú csomagolóanyagok között találhatunk szépeket,
                        de ha
                        valami eredetibbre
                        vágyunk, használhatunk teljesen egyszerű, sima csomagolópapírt, kössünk rá szalagot és díszítsük
                        fehér festékkel, levelekkel, ágakkal.</p>
                </div>

                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="./assets/crop/csomagolas-2.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Utánozhatjuk egy postai küldemény megjelenését egy
                        egyszerűbb csomagolópapírral, nagy postabélyegekkel, postabélyegzővel, színes zsinórral átkötve
                        – címezzük meg és írjuk alá, hogy teljes legyen a kép.</p>
                </div>

                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 '}><img src="./assets/crop/csomagolas-3.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>A gyerekeknek biztosan örömet szerez majd, ha a
                        klasszikus módszerrel, ajándék tartó zokniba tesszük a kisebb meglepetéseket, ezeket horgokra
                        akasztva lógathatjuk is sorban a fa mellett.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="./assets/crop/csomagolas-4.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>A kis ajándéktárgyak és édességek nagyszerűen néznek ki
                        szép üvegedényekben, díszítsük madzaggal és kézműves hatású üdvözlőkártyával – az üveg az
                        ajándékozás után is megmarad, emlékeztetőül a szép pillanatokra.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 '}><img src="./assets/crop/csomagolas-5.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Tegyük az ajándékokat előre elkészített vagy házi készítésű
                        dobozokba (utóbbi esetben keményebb kartonból mi is elkészíthetjük a dobozt egy az internetről
                        letöltött sablon alapján). A megvásárolható dobozok között biztosan találunk szépet, de egyedibb
                        lesz az ajándék, ha magunk csomagoljuk egy szép, skandináv mintájú papírba.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="./assets/crop/csomagolas-6.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Ha a doboz helyett valami érdekesebb formát szeretnénk,
                        az interneten találhatunk sablonokat tasakokhoz is, vastagabb, szép mintával díszített papírból
                        hajthatjuk össze.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 '}><img src="./assets/crop/csomagolas-7.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Engedjük szabadon a fantáziánkat, hiszen az ajándékokat nem
                        kötelező az unalomig ismert karácsonyi csomagolópapírokba burkolni. Használhatunk erre a célra
                        pl. térképet (utazók nagy örömére), de egy újságpapírból is remek dolgokat hozhatunk ki.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="./assets/crop/csomagolas-8.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Maradt valamennyi tapéta a lakásdekorációból?
                        Felhasználhatjuk azt is, de ha ügyesen bánunk a textilekkel, akkor egy maradék anyagból is
                        készíthetünk egyedi csomagdíszt.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 py-6'}>
                    <div className={'order-1'}><img src="./assets/crop/csomagolas-9.jpg"
                                                    alt=""
                                                    className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Ha valaki szeretne eltérni a szokványos piros, zöld,
                        télapós, fenyőfás, angyalkás csomagolástól, válasszon elegáns fekete-fehér csomagolópapírokat,
                        kreatív díszítéssel. A fűszerek, bogyók, tobozok szépen passzolnak a fekete-fehér
                        csomagoláshoz.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="./assets/crop/csomagolas-10.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Bármelyik csomagolást is választjuk, egy kis extra
                        díszítés nem árthat. Legyünk kreatívak, növényekkel, levelekkel, fenyőágakkal.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 py-6'}>
                    <div className={'order-1 '}><img src="./assets/crop/csomagolas-11.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Egy szép ezüst, fehér, bézs papírt nagyszerűen feldobhatunk kisebb
                        karácsonyi díszekkel, papír csillagokkal, szalagokkal, csipkékkel, textíliákkal és apró
                        figurákkal.</p>
                </div>


            </>
    },
    {
        id: 17,
        title: "Így készítsd jól a forralt bort!",
        content:
            <>
                <h2>Így készítsd jól a forralt bort!</h2>
                <div>
                    <img src="assets/crop/forralt-bor.jpg" alt="" className={'my-4 rounded'}/>
                </div>
                <h4>A forralt bor az egyik legnépszerűbb téli ital, nem véletlenül! Finom lélekmelegítő, ami ráadásul
                    többféleképp is elkészíthető.</h4>
                <div className="grid md:grid-cols-4 gap-x-2">
                    <div>
                        <h3>Hozzávalók</h3>
                        <h4 className={'mb-2'}>A vörösboroshoz:</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>750 ml száraz vörösbor pl. kékfrankos</li>
                            <li>1 - 2 szem aszalt szilva (opcionális)</li>
                            <li>1 evőkanál olívaolaj</li>
                            <li>2 rúd fahéj</li>
                            <li>1 - 3 szelet friss gyömbér</li>
                            <li>5 db szegfűszeg</li>
                            <li>3 ek cukor</li>
                            <li>1 db csillagánizs</li>
                            <li>200 ml narancslé vagy víz</li>
                            <li>1 db narancs héja</li>
                        </ul>
                        <h4 className={'mb-2'}>A fehérboroshoz:</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>750 ml száraz fehérbor pl. olaszrizling</li>
                            <li>1 db alma kockázva</li>
                            <li>3 ek méz</li>
                            <li>1 ek vaníliás cukor</li>
                            <li>2 rúd fahéj</li>
                            <li>4 - 5 db szegfűszeg</li>
                            <li>1 db citrom héja hámozva</li>
                            <li>150 ml almalé</li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>A forralt bor alapja legtöbbször vörösbor. Nem szükséges a felső kategóriásak közül
                            választani, mert a sokféle fűszernek köszönhetően nem a bor íze érvényesül. Legjobb egy
                            közepes árfekvésű kékfrankost használni hozzá.
                        </p>
                        <p>A bort egy gyorsforralóba öntjük, remekül passzol hozzá az aszalt szilva, de a hagyományos
                            fűszerekről se feledkezzünk meg. Tehetünk bele fahéjat, gyömbért, szegfűszeget, és ízlés
                            szerint ízesítjük cukorral és egy kevés csillagánizzsal is. Ez nagyon intenzív fűszer,
                            csínján bánjunk vele!
                        </p>
                        <p>
                            Az italt vízzel vagy kevés narancslével hígítjuk, ha pedig kedveljük a citrusos vonalat, a
                            narancs héját se hagyjuk ki. Ezt egy zöldséghámozóval szedjük le, arra figyeljünk, hogy a
                            fehér részek ne kerüljenek bele, mert keserűvé teheti a végeredményt.
                        </p>
                        <p>
                            A könnyedebb fehérboros verzióhoz a legideálisabb, ha olaszrizlinggel dolgozunk. Ízesítés és
                            fűszerezés terén az alma, a méz és a vaníliás cukor is fantasztikus ízeket ad neki, de
                            persze a
                            fahéj és a szegfűszeg itt is elmaradhatatlan. Ezek mellett citromhéj és almalé illik hozzá.
                            A
                            bort közepes lángon főzzük körülbelül 15-20 percig, vagy amíg eléri a kívánt forróságot.
                        </p>
                        <p>
                            Az ital alkoholtartalma átlagosan 7-10% körüli, a bor típusától függően. Nem igaz a tévhit,
                            hogy
                            az alkohol elpárolog belőle, ráadásul a hőmérsékletnek és a cukortartalomnak köszönhetően
                            még
                            hamarabb érezhető a hatása!
                        </p>
                        <p>
                            Gyerekeknek, vagy akik nem isznak alkoholt, inkább gyümölcsleves alapot használjunk. Erre a
                            célra az alma- vagy szilvalé lesz a legalkalmasabb, de kísérletezhetünk más ízekkel is. </p>

                    </div>
                </div>
            </>

    },
    {
        id: 18,
        title: "Aszaltszilvás-mákos bonbon",
        content:
            <>
                <h2>Aszaltszilvás-mákos bonbon</h2>
                <div>
                    <img src="assets/crop/aszalt-szilvas-makos-bonbon.jpg" alt="" className={'my-4 rounded'}/>
                </div>
                <h4>Mivel Ti kedvelitek a gyors és egyszerű, mégis finom receptjeinket, mi meg kedvelünk benneteket, így
                    karácsony közeledtével egy villámgyors ehető ajándékot mutatok nektek. Érdemes dupla adagot
                    készíteni, hiszen egészen biztos, ha megkóstolod, elgondolkozol rajta, hogy továbbadd-e. </h4>
                <div className="grid md:grid-cols-4 ">
                    <div>
                        <h3>Hozzávalók</h3>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>100 g háztartási keksz</li>
                            <li>15 g porcukor</li>
                            <li>30 g puha vaj</li>
                            <li>200 g fehér csoki</li>
                            <li>1 tk egész mák</li>
                            <li>100 g aszalt szilva</li>
                            <li>1 db narancs</li>
                            <li>50 g darált mák</li>
                            <li>1 ek olaj</li>
                        </ul>
                    </div>
                    <div className={'col-span-3'}>
                        <p>Az aszaltszilvás-mákos bonbonhoz a kekszet egy konyhai aprítógépben összezúzzuk, egy
                            keverőtálba tesszük, majd az aszalt szilvát is krémesre zúzzuk. Hozzáadjuk a porcukrot, a
                            narancs reszelt héját és a felének a levét, a puha vajat, a darált mákot és az egészet
                            összedolgozzuk. Kis, kb. dióméretű gombócokat formázunk a masszából.
                        </p>
                        <p>
                            A csokoládét vízgőz fölött megolvasztjuk az olajjal, majd belemártjuk a bonbonokat.
                            Lecsepegtetjük, és egy zsírpapírra tesszük a kész bonbonokat, végül a tetejüket megszórjuk
                            egész mákkal.
                        </p>
                        <p>
                            Hagyjuk, hogy megdermedjenek, és már csomagolhatjuk is be, hogy örömet szerezzünk ezzel az
                            ehető ajándékkal a szeretteinknek vagy akár saját magunknak. 😉</p>
                    </div>
                </div>
            </>

    },
    {
        id: 19,
        title: "Vörösboros kacsacomb polentával",
        content:
            <>
                <h2>Vörösboros kacsacomb polentával</h2>
                <div>
                    <img src="assets/crop/voros-boros-kacsa.jpg" alt="" className={'my-4 rounded'}/>
                </div>
                <h4>Ha valami, hát ez egy igazi ünnepi étel. Lassan készül és ezért mély, gazdag ízeivel teljesen
                    elvarázsol. Az édeskés-savanykás mártás mellé a krémes, puha polenta tökéletes köret.</h4>
                <div className="grid md:grid-cols-4 gap-x-2">
                    <div>
                        <h3>Hozzávalók</h3>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>4 kacsacomb</li>
                            <li>só és bors</li>
                            <li>1 evőkanál olívaolaj</li>
                            <li>2,5 dl száraz vörös bor</li>
                            <li>10 dkg aszalt meggy</li>
                            <li>10 dkg aszalt feketecseresznye</li>
                            <li>5 dkg mazsola</li>
                            <li>1 fej fokhagyma</li>
                            <li>2 teáskanál kakukkfű</li>
                            <li>1 csipet cayenne bors</li>
                            <li>1 l szárnyas alaplé</li>
                            <li>2 teáskanál kukoricakeményítő</li>
                            <li>2 evőkanál kapribogyó</li>
                            <li>2 evőkanál finomra aprított petrezselyem</li>
                            <li>1 bio citrom héja és leve</li>
                        </ul>
                        <h4 className={'mb-2'}>A polentához:</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>20 dkg kukoricadara</li>
                            <li>1 l víz</li>
                            <li>7 dkg frissen reszelt parmezán</li>
                            <li>5 dkg vaj</li>
                            <li>1 tk só</li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>A kacsacombokon a bőrt beirdaljuk, majd sózzuk és borsozzuk őket. Az olajat közepes lángon
                            felhevítjük egy vaslábasban, majd bőrükkel lefelé rátesszük a kacsacombokat és kíméletesen
                            lepirítjuk őket (8-9 perc alatt). Euztán megfordítjuk a combokat, és további 5 percig
                            pirítjuk. A felesleges zsírt leöntjük és kiszedjük a combokat. A lábasba öntjük a bort,
                            felére beforraljuk, közben felkaparjuk a lábas aljára ragadt sült húsdarabokat. Hozzáadjuk
                            az aszalt gyümölcsöket, a fokhagymát, kakukkfüvet és a csilipelyhet. Visszatesszük a
                            kacsacombokat és felöntjük annyi alaplével, amennyi 2/3 részéig ellepi a húst.
                            Felforraljuk, majd áttesszük a 170 fokra előmelegített sütőbe, ahol 1,5 órá sütjük fedő
                            alatt.
                        </p>
                        <p>Ha puhára sültek, a kacsacombokat kivesszük a lábasból és melegen tartjuk. A lábasban marad
                            mártásról leszedjük a zsírt, a mártást pedig felére beforraljuk, közben hozzáadjuk a
                            gyöngyhagymát. Ha tűl híg a mártás, kikeverünk 1 ek kukoricakeményítőt 2 ek vorös borral és
                            a mártáshoz adjuk, addig főzzük, amíg az besűrűsödik. Végül beállítjuk az ízeket egy kevés
                            citromlével, sóval, borssal. Tálaláskor megszórjuk a maradék petrezselyemmel és krémes
                            polentát adunk mellé.
                        </p>
                        <p>
                            A polentához a vizet felforraljuk, sózzuk és megvárjuk, amíg újra felforr. Lassan
                            beleszórjuk a kukoricadarát és 5 percig keverjük közepesen alacsony láng felett. Néha
                            megkeverve addig főzzük, amig a dara megpuhul és elválik a lábos oldalától. Lehúzzuk a
                            tűzről, hozzákeverjük a puha vajat és a reszelt paremzánt. Tálalásig melegen tartjuk, a
                            tetejére nedves sütőpapírt teszünk, hogy ne bőrösödjön.
                        </p>

                    </div>
                </div>
            </>
    },
    {
        id: 20,
        title: "Aranyos kép  - Micimackó és malacka",
        content:
            <>
                <h2>20. nap</h2>
                <img src="assets/crop/winnie.png" alt="" className={'my-4 mx-auto rounded hidden md:block'}/>
                <img src="assets/crop/winnie_mobil.png" alt="" className={'rounded md:hidden'}/>
            </>
    },
    {
        id: 22,
        title: "Rocker manók videó",
        content:
            <>
                <h2>22.nap</h2>
                <video src="assets/crop/videos/elfrock.mp4" muted autoPlay controls></video>
            </>
    },
    {
        id: 23,
        title: "10 hely, ahol az eszem-iszom helyett a játékról szól a csapatépítő",
        content:
            <>
                <h2>10 hely, ahol az eszem-iszom helyett a játékról szól a csapatépítő</h2>
                <div className={'text-justify mb-6'}>
                    <h3>Sétáljatok a városban!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/crop/csapat-1.jpg"
                                                             alt=""
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Csapatépítés városi sétával? Miért ne?! Szerintünk azért is jó választás, mert amellett, hogy
                        együtt
                        vagytok, a várost is jobban megismeritek, felfedezhettek egy sor olyan helyet, amiről lehet,
                        hogy
                        még nem is tudtatok, ráadásul egy sor budapesti legendáról is hallhattok. Sőt, ezeken az
                        eseményeken
                        nemcsak járkálhattok A-ból B-be, de a beépített játékelemekkel kompetitív és kooperatív
                        csapatépítés
                        is lehet a sétából. Az Imagine-nél a tematikus, a kincskeresős és a rapid városnézés közül
                        válogathattok, de akár teljesen a csapatra szabott programot is rendelhettek, míg a Budastepnél
                        érdemes kipróbálnotok a Várszámháborút.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Találjátok meg közösen a szabadulás útját a Neverland Bar & Escape Roomban!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/crop/csapat-2.jpg"
                                                             alt=""
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Lehet, hogy elsőre nem jutna eszünkbe, hogy egy szabadulószobás csapatépítőt szervezzünk, holott
                        a
                        logikai feladatok és a rejtélyes fejtörők megoldása elég jól összekovácsolhatja a csapatot,
                        ráadásul
                        ma már az elvarázsolt kastélytól a vadnyugati pókerteremig válogathatunk a különféle tematikus
                        szobák közül. A Dohány utcai Neverland hét, különböző tematikájú szobával bár benneteket, és már
                        csak azért is szuper választás, mert akár 120 fővel is mehettek. Egyszerre 40 fő tud
                        szabadulószobázni, miközben a csapat többi része megebédelhet vagy részt vehet egy italkóstolón,
                        ha
                        pedig a teljes helyszínt kibérlitek, akkor DJ-t vagy karaokét is kérhettek. Kis csapatban
                        érkezőknek
                        – max. 15 fő – pedig a VIP-terem szolgál felejthetetlen és teljesen privát élményként. (x)</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Barkácsoljatok valamit közösen!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/crop/221232.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Mindig jó dolog készíteni valamit, de még jobb, ha ezt közösen teszitek. A napi irodai munka
                        szellemileg elég kimerítő tud lenni, úgyhogy igazi felüdülés, ha lehetőségetek nyílik kétkezi
                        munkával foglalkozni és alkotni, ráadásul itt is rengeteget tanulhattok a csapatmunkáról. Attól
                        pedig egyáltalán nem kell megriadni, ha valaki még életében nem fűrészelt ketté egy lécet vagy
                        nem
                        használt még gyalut, mert a szakemberek megtanítanak rá mindenkit. Ha elmélyülnétek a
                        barkácsolásban, akkor nemcsak magatoknak és a közösségnek készíthettek tárgyakat, de akár
                        kutyamenhelyek számára is készíthettek fából kutyaházakat. A Woodivity minden workshopja kérhető
                        csapatépítőnek is, míg a Creator Nyitott Műhelyben izgalmas kísérlet lehet lemodelleznetek az
                        üzleti
                        folyamatokat.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Készítsetek finom ételeket egy főzőiskolában!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/crop/csapat-4.jpeg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Ha ti is egy olyan irodában dolgoztok, ahol mindenki imád enni és keresi a jobbnál jobb kulináris
                        élvezeteket, de a közösségi sütögetés és bográcsozás helyett valami másra vágynátok, akkor
                        érdemes a
                        csapatépítőt egy főzőiskolába szervezni. Egy ilyen helyzetben olyan oldalát is megismerhetitek a
                        kollégáitoknak, ami a meetingeken vagy a szomszéd asztalnál ülve nem feltétlenül tűnik fel, de
                        cinkosan össze is mosolyoghattok, ha a receptben írt mennyiség helyett titokban többet tesztek
                        az
                        ételbe. Az már csak hab a tortán, hogy az elkészített finomságokat utána közösen megehetitek. Mi
                        ajánljuk többek között a Makifood Főzőiskolát, a Negura Cooking japán és sri lankai főzőiskola
                        kurzusait vagy az Akademia Italia óráit.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Korcsolyázzatok a Budapest Parkban!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/crop/csapat-5.jpeg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Már tavaly is imádtuk, hogy az egyik kedvenc koncerthelyszínünk óriási koripályává változott,
                        ahol a
                        melengető italok és a káprázatos fények között igazi varázsvilágban éreztük magunkat. Idén
                        decembertől újra csúszhat mindenki a Budapest Park Jégvilágában, ami szerintünk szuper ötlet, ha
                        télvíz idején szervezitek a csapatépítőt. Sőt, ha nem szeretnétek másokkal közösködni, akkor az
                        egész Jégvilág – vagyis a teljes koripálya, ami idén nagyjából 1500 m² – a tiétek lehet, de
                        kibérelhettek egy kis pályaszakaszt is, ami a backstage felé nyúlik ki. Utóbbi zárt körű, 200
                        fős
                        rendezvény befogadására alkalmas tér, ahonnan akár a nagy pályára is kicsúszhattok, azonban a
                        csapatot senki sem fogja itt zavarni, hiszen a zárt részre csak ti korizhattok be. Ha titeket is
                        felcsigázott a lehetőség, és alig várjátok, hogy ide szervezzétek a csapatépítőt, a
                        sales@budapestpark.hu címen tudtok érdeklődni. (x)</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Keressetek kincseket a városban!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/crop/csapat-6.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Ha a városnézést kiegészítenétek még egy kis extrával, akkor próbáljátok ki a kincskeresést, ahol
                        saját tempóban menetelhettek, fedezhetitek fel a várost, és ebben a kalandban még közös kihívást
                        is
                        találtok. Na de ezeket a játékokat nem is olyan egyszerű megoldani, valódi csapatmunka kell a
                        túra
                        teljesítéséhez, amihez érdemes mindenki ötletét meghallgatni és közösen kitalálni, melyik lehet
                        a
                        legjobb megoldás. Hogy miért? Mert a több kisebb csapat egymással versenyez – legalábbis az
                        UrbanGo
                        pályáin. </p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Tökéletes összhang a Normafa Club House-ban</h3>
                    <div className={'float-right ml-3'}><img src="./assets/crop/csapat-7.jpeg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Az egyik legkedveltebb hely Budapesten a Normafa, ahová mi is gyakran kirándulunk, ha éppen nincs
                        időnk hosszú túrákat tenni, de már nagyon kiszakadnánk a városi létből. Ha valami egészen
                        komplex és
                        holisztikus csapatépítőben gondolkoztok, akkor szuper választás a Budapest tetején lévő Normafa
                        Club
                        House, ahol mindent egyben, tökéletes összhangban érhettek el. A tematikus kompetenciafejlesztő
                        tréningektől kezdve itt mindent megtaláltok, ami egy csoportos elvonuláshoz kell. Míg a
                        tréningtermekben 8-50 fő tud izgalmas workshopokon részt venni, addig a nagy színházteremben
                        akár
                        110 fő is elfér. 60 csodaszép szoba teszi lehetővé a többnapos programok szervezését, a hely
                        konyháján pedig nemzetközi séfgárda biztosítja a kulináris élményt. Egészséges alapanyagok
                        felhasználásával széles választékban rendelhetjük a hazai és a nemzetközi konyha
                        különlegességeit,
                        illetve vegetáriánus, vegán és ajurvédikus ételeket is. Választhatjuk helyszínként továbbá a
                        Dómot,
                        ahol akár 300 fő számára is lehet koncertet rendezni, de más típusú programnak is helyet adhat.
                        Ha
                        pedig Dóm, akkor ha wellnessélményre vágyik a csapat, a kültéri medence hamarosan egy dómmal
                        lesz
                        lefedve, így egy „buborékban” pancsolhattok, emellett pedig egy kültéri jakuzzimedencét is
                        találtok.
                        (x)</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Jótékonykodjatok!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/crop/csapat-8.jpeg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Szerintünk szuper ötlet, ha a „kötelező” programot összekötitek a jótékonysággal, így nemcsak a
                        kollegiális viszony lesz erősebb, de még segítetek is másokon. Süthettek-főzhettek
                        rászorulóknak,
                        készíthettek játékokat gyerekeknek, elmehettek szemetet szedni budapesti erdőkbe és
                        patakpartokra,
                        készíthettek kutyaházat, vagy segíthettek óvodák és iskolák felújításában. Szerintünk mindegyik
                        szép
                        gesztus, ráadásul sokkal motiváltabbak lesztek, ha egy valódi célért dolgoztok össze.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Játékos összekovácsolódás a Fröcskölőben</h3>
                    <div className={'float-right ml-3'}><img src="./assets/crop/csapat-9.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Ha közösen szeretnétek megszabadulni a feszkótól, akkor szuper ötlet kipróbálni a Fröcskölőt a
                        Blaha
                        Lujza tér közelében, ami sokkal humánusabb stresszlevezető, mint ha beszabadulnátok egy
                        dühöngőbe
                        törni-zúzni. Ez az a hely, ahol biztos, hogy minden csupa móka és kacagás lesz, főleg, mert a
                        védőruhában kicsit úgy érezhetitek magatokat, mintha a Breaking Bad szereplői lennétek, de a
                        színes
                        festékekkel biztos, hogy nem csak a vásznat fogjátok lefesteni. A Fröcskölő után tegyünk egy
                        sétát a
                        Szelfimúzeumba, és szórakoztassuk kollégáinkat azzal, hogy különböző berendezett háttereknél
                        készítünk együtt egy-egy vicces vagy hatásos szelfit, ami remekül mutathat közös emlékként az
                        iroda
                        falán. Azért is éri meg kitérőt tenni ide, mert egy olyan, kombinált jegyet vásárolhatunk, ami
                        egyszerre érvényes a Fröcskölőbe és a múzeumba is.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>
                        Fedezzétek fel a főváros környéki kirándulóhelyeket!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/crop/csapat-10.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Ha már végképp elegetek van a benti létből, és a céges csapatépítőt is inkább a szabadba
                        terveznétek,
                        akkor fedezzétek fel a Budapest környéki túraútvonalakat, amikből mi is megírtunk már jó párat.
                        Ha
                        nem szeretnétek messzire menni, akkor a fővároson belül is találtok olyan helyeket, amiket a
                        kollégákkal együtt is felfedezhettek, a pihenőréteken pedig megállhattok bográcsozni, de akár a
                        csapatot összekovácsoló feladatokat is játszhattok. Ha hirtelen nincs tippetek, merre menjetek,
                        nyugodtan böngésszetek az e-rdei útvonalak, a kilátók és a különféle kirándulóhelyek között. Mi
                        mindegyiket imádjuk.</p>
                </div>

            </>
    },
    {
        id: 24,
        title: "Boldog Karácsonyt aranyos videó",
        content:
            <>
                <h2>Boldog karácsonyt kívánunk!</h2>
                <div>
                    <video src="assets/crop/videos/karacsony.mp4" className={'my-4 mx-auto'} muted autoPlay loop controls/>
                </div>

            </>
    },
    {
        id: 11,
        title: "Top10: kihagyhatatlan karácsonyi filmek decemberre",
        content:
            <>
                <h2>Top10: kihagyhatatlan karácsonyi filmek decemberre</h2>
                <h4>Válassz a listában szereplő filmek közül, és hagyd, hogy magával ragadjon a karácsonyi
                    hangulat!</h4>
                <div className="grid ">
                    <div>
                        <div className="col-xs-12">


                            <p>1.<strong> Grincs </strong>– Jim Carrey, Kik, karácsony</p>

                            <p>2. <strong>Reszkessetek betörők</strong> – nincs karácsony
                                Kevin nélkül</p>

                            <p>3.<strong> Reszkessetek betörők 2</strong>. - továbbra sincs
                                karácsony Kevin nélkül</p>

                            <p>4. <strong>Reszkessetek betörők 3.</strong> - van karácsony
                                Kevin nélkül, de minek?!</p>

                            <p>5. <strong>Let It Snow</strong> - szabadnapos sztár és a
                                tipikus lány a szomszédból találkozása a Netflixen</p>

                            <p>6.&nbsp;<strong>Holiday</strong> – Amerika, Anglia, szerelem,
                                karácsony</p>

                            <p>7. <strong>Hull a pelyhes</strong> – Turbo Man, Arnold
                                Schwarzenegger, karácsonyi őrület</p>

                            <p>8. <strong>Igazából szerelem</strong> – kétségtelenül a
                                valaha volt legjobb karácsonyi film</p>

                            <p>9. <b>Last Christmas -&nbsp;</b>a sárkányok anyja,&nbsp;a
                                Crazy Rich Asians főszereplője, London és persze karácsony</p>
                            <p>10. <strong>Tapló télapó</strong>- a Mikulás bácsi bunkó,
                                faragatlan kiadása</p>
                        </div>
                    </div>
                </div>
            </>
    },
    {
        id: 23,
        title: "Collect correct gifts játék",
        content: <>
            <BallsGame/>
        </>
    },
    {
        id: 2,
        title: "Zene - Ed Sheeran&Elton John - Merry Christmas",
        content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Q_yuO8UNGmY"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/Q_yuO8UNGmY"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    },
    {
        id: 3,
        title: "Mikulás keksz",
        content:
            <>
                <h2>Mikulás keksz</h2>
                <div>
                    <img src="assets/bayer2023/mikulas-keksz.jpg" alt="" className={'my-4 rounded-lg'}/>
                </div>
                <h4>Tápláló finomság a Nagyszakállúnak a hosszú útra, na meg persze a gyerekeknek!
                    Van egy olyan szokás, amikor a gyerekek a Mikulásnak kikészítik az ablakba a kekszet és a tejet. Ez lehet az a bizonyos keksz, hiszen nagyon tápláló, nem száraz, így a Mikulásnak biztosan lesz energiája minden gyerek csizmájába ajándékot rejteni.</h4>
                <div className="grid md:grid-cols-5 gap-x-2">
                    <div className={'md:col-span-2'}>
                        <h3>Hozzávalók</h3>
                        <p>18 darabhoz</p>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>10 - 12 db datolya (kb. 90 g)</li>
                            <li>90 g dió</li>
                            <li>4 g őrölt lenmag</li>
                            <li>½ tk vaníliaőrlemény</li>
                            <li>1 csipet só</li>
                            <li>1 ½ g szódabikarbóna</li>
                            <li>20 ml víz</li>
                            <li>1 csipet fahéj</li>
                            <li>2 ½ g almaborecet vagy 1 db tojás</li>
                            <li>40 g csokipasztilla (kihagyható)</li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>Előkészítési idő: 20 perc | Sütési idő: 15 perc | Összesen: 35 perc</p>
                        <p>A mikulás keksz elkészítéséhez a datolyát 2 dl forró vízbe áztatjuk 15 percre. A diót ledaráljuk (ha a gyermek 3 éven aluli, egyáltalán ne legyen benne kis darab dió sem). A dió mellé, az aprítóba tesszük a megduzzadt datolyákat, a lenmagot, a vaníliát, a sót, a szódabikarbónát, a vizet, a fahéjat és az ecetet vagy tojást. Addig aprítjuk, amíg a tészta sima és ragadós nem lesz. A masszához keverjük a csokipasztillát, majd már csak fakanállal jól átkeverjük.
                        </p>
                        <p>Sütőpapírral bélelt tepsire kanalazzuk a masszát egy evőkanál vagy egy kisebb fagyiskanál segítségével, ujjainkkal kicsit lenyomkodjuk őket kekszformára. 180 fokra előmelegített sütőben 13-14 percig sütjük, amíg a szélük aranybarna nem lesz. Ha szeretnénk, a tetejére még tehetünk egy-egy csokipasztillát, rá fog olvadni. Sütés után hagyjuk őket teljesen kihűlni a tepsin, hiszen nagyon törékenyek még melegen.
                        </p>
                        <p><strong>TIPP:</strong> Vegán édesség, ha tojás helyett borecettel készítjük.</p>
                        <p><strong>Forrás:</strong> <a href="https://streetkitchen.hu/karacsony/karacsonyi-receptek-gyerekeknek/mikulas-keksz/" target={"_blank"}>https://streetkitchen.hu/karacsony/karacsonyi-receptek-gyerekeknek/mikulas-keksz/</a></p>
                    </div>
                </div>
            </>

    },
    {
        id: 4,
        title: "Beszélgessetek :) Itt találsz néhány beszélgetős társasjáték ötletet",
        content:
            <>
                <h2>Beszélgessetek :) Itt találsz néhány beszélgetős társasjáték ötletet </h2>
                <p className={'my-4 text-base lg:text-md'}>Tekintsd meg a gémklub összefoglalását kommunikációs társasjátékokból, hiszen mi sem fontosabb a mai világban, mint hogy megtanuljuk kifejezni saját, és megérteni mások gondolatait a kölcsönös elfogadás céljából. </p>
                <a href={"https://www.gemklub.hu/parti-tarsasjatekok/kommunikacios-tarsasjatekok"} target={"_blank"} className={'btn-primary mx-auto w-max'}>Megnézem</a>
            </>
    },
    {
        id: 6,
        title: "Ismered a WMN: Beszélnünk kell podcastját?",
        content: <>
            <h2 className={'md:text-4xl'}>Ismered a WMN: Beszélnünk kell podcastját?</h2>
            <p className={'my-4 text-base lg:text-md'}>Emberekről. Kapcsolatokról. Magunkról.</p>
            <img src="assets/bayer2023/wmn-podcast.jpg" alt=""/>
            <a href={"https://youtube.com/playlist?list=PL0uY1N5xwplV54nxRs75A8fVJ0VIzQBnF&si=VXJFPdgJ32emjB90"} target={"_blank"} className={'btn-primary mx-auto w-max mt-4'}>Megnézem</a>
        </>
    },
    {
        id: 7,
        title: "Karácsonyi vega pite",
        content: <>
            <h2>Karácsonyi vega pite</h2>
            <div>
                <img src="assets/bayer2023/vega-pite.jpg" alt="" className={'my-4 rounded-lg'}/>
            </div>
            <h4>Egy isteni vegetáriánus pite teljesen karácsonyra hangolva. A téli fűszerektől egészen különleges ízt kap, így ha feldobnátok valami újjal a karácsonyi asztalt, akkor ne habozzatok!</h4>
            <div className="grid md:grid-cols-5 gap-x-2">
                <div className={'md:col-span-2'}>
                    <h3>Hozzávalók</h3>
                    <p>1 23 cm átmérőjű piteformához</p>
                    <h4 className={'mb-2'}>A pitetésztához</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>400 g liszt</li>
                        <li>200 g vaj</li>
                        <li>200 g sajtkrém</li>
                        <li>2 mk só</li>
                        <li>160 g parmezán reszelve</li>
                        <li>kb. 100 ml hideg víz</li>
                    </ul>
                    <h4 className={'mb-2'}>A töltelékhez</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>2 db krumpli (2 db / 300 g)</li>
                        <li>só</li>
                        <li>4 - 5 ek olívaolaj</li>
                        <li>1 fej hagyma (1 fej / 150 g)</li>
                        <li>1 - 2 gerezd fokhagyma</li>
                        <li>2 csomag CREAVEG darált húshelyettesítő (200 g-os kiszerelés)</li>
                        <li>kb. 0,5 tk őrölt bors</li>
                        <li>½ tk őrölt fahéj</li>
                        <li>½ mk őrölt szegfűszeg</li>
                        <li>frissen reszelt szerecsendió</li>
                        <li>½ tk kakukkfű</li>
                        <li>3 - 4 levél friss zsálya</li>
                    </ul>
                    <h4 className={'mb-2'}>A tetejére</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>1 db tojás</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <p>A karácsonyi vega pite elkészítéséhez először a tésztával kezdünk, mert annak pihennie kell majd a hűtőben. A lisztet, a vajat, a sajtkrémet, a sót és a parmezánt robotgépben morzsásra dolgozzuk, majd fokozatosan annyi hideg vizet adunk hozzá, hogy összegyúrva tészta állagot kapjunk. Fóliába csomagoljuk, és a hűtőbe tesszük 30-40 percre.
                    </p>
                    <p>A töltelékhez a meghámozott krumplikat egészben, sós vízben megfőzzük. Egy másik edényben olívaolajat melegítünk, és rádobjuk az apróra vágott hagymát. Ha már megdinsztelődött, akkor jöhet rá a zúzott fokhagyma, majd a húspótló, végül az áttört krumpli. Összekeverjük, és mehet hozzá 1 tk só, valamint a fűszerek is, ezzel is alaposan elkeverjük, és adunk neki kb. 5 percet, majd levesszük a tűzről, és félretesszük.</p>
                    <p>Kettévágjuk a tésztát, és az egyiket kerek formára nyújtjuk, beletesszük a piteformába, és jöhet bele a töltelék. A tészta másik felét is kinyújtjuk, és szépen ráillesztjük a formára. A felesleges részeket levágjuk, a széleket alaposan összenyomkodjuk, majd a pite tetejét a felvert tojással megkenjük, és megszurkáljuk, hogy a hő távozni tudjon, és a pite ne dudorodjon fel.</p>
                    <p>180 fokra előmelegített sütőben 30-40 percig sütjük. Mikor elkészült, hagyjuk egy picit hűlni, és csak utána szeleteljük fel.</p>
                    <p><strong>Forrás:</strong> <a href="https://streetkitchen.hu/karacsony/vega-karacsony/karacsonyi-vega-pite/" target={"_blank"}>https://streetkitchen.hu/karacsony/vega-karacsony/karacsonyi-vega-pite/</a></p>
                </div>
            </div>
        </>
    },
    {
        id: 8,
        title: "Aranyos kép - May your days be merry…",
        content: <>
            <h2>8. nap</h2>
            <div>
                <img src="assets/bayer2023/dec8.jpg" alt="" className={'my-4 rounded-lg hidden lg:block lg:max-w-[580px] 2xl:max-w-full'}/>
                <img src="assets/bayer2023/dec8-mob.jpg" alt="" className={'my-4 lg:hidden'}/>
            </div>
        </>
    },
    {
        id: 9,
        title: "A Karácsony ízei püspökkenyérbe zárva",
        content: <>
            <h2>A Karácsony ízei püspökkenyérbe zárva</h2>
            <div>
                <img src="assets/bayer2023/puspokkenyer.jpg" alt="" className={'my-4 rounded-lg'}/>
            </div>
            <h4>A püspökkenyér tipikus karácsonyi süti és körülbelül 623 recept kering róla az interneten... :D Nem csoda, hiszen mindenki variál rajta valamit, de a lényeg, hogy egy alaprecepttel mindenképp jól jártok. Mi most mutatunk egyet, ami azon felül, hogy elképesztően finom, glutén-és laktózmentes is és hozzáadott cukrot sem tartalmaz!</h4>
            <div className="grid md:grid-cols-5 gap-x-2">
                <div className={'md:col-span-2'}>
                    <h3>Hozzávalók</h3>
                    <p>1 db őzgerincformához</p>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>600 g aszalt és kandírozott gyümölcs vegyesen (meggy, szilva, datolya, áfonya, mazsola, narancshéj, barack, gyömbér…)</li>
                        <li>50 g darált mogyoró</li>
                        <li>200 g darált mandula</li>
                        <li>3 db tojás</li>
                        <li>1 csipet só</li>
                        <li>1 mk őrölt fahéj</li>
                        <li>2 csipet őrölt szegfűszeg</li>
                        <li>2 csipet őrölt szerecsendió</li>
                        <li>25 ml olívaolaj</li>
                        <li>1 db narancs reszelt héja</li>
                        <li>1 db citrom reszelt héja</li>
                        <li>½ db vaníliarúd kikapart belseje</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <p>A gyömölcsöket apróra vágjuk. Egy tálba rakjuk az összes hozzávalót, jól átkeverjük és egy kiolajozott, darált mandulával megszórt őzgerincformába simítjuk a masszát. 170 fokos sütőben 35-40 percig sütjük a püspökkenyeret.
                    </p>
                    <p><strong>Forrás:</strong> <a href="https://streetkitchen.hu/meguszos-sutik/karacsony-izei-puspokkenyerbe-zarva/" target={"_blank"}>https://streetkitchen.hu/meguszos-sutik/karacsony-izei-puspokkenyerbe-zarva/</a></p>
                </div>
            </div>
        </>
    },
    {
        id: 10,
        title: "Ismered az Éva magazin - Micsoda nők voltak podcastját?",
        content: <>
            <h2 className={'md:text-4xl'}>Ismered az Éva magazin - Micsoda nők voltak podcastját?</h2>
            <p className={'my-4 text-base lg:text-md'}>A Micsoda nők voltak! az Éva Magazin podcast műsora, amelynek minden részében más-más olyan nőről fogunk beszélgetni, akik bár már nem élnek, de érdemes róluk beszélni, megismerni életüket, munkásságukat. Érdekes, szórakoztató köntösben, túl a száraz évszámokon. A műsorvezetők: Budai Lotti történelmi regényíró és Zubor Rozi, újságíró, az ÉvaMagazin.hu főszerkesztője.</p>
            <img src="assets/bayer2023/micsoda-nok-podcast.jpg" alt=""/>
            <a href={"https://www.youtube.com/playlist?list=PLd5zQijosOtgbsQK7BIGB4jSXqQC6jDfB"} target={"_blank"} className={'btn-primary mx-auto w-max mt-4'}>Megnézem</a>
        </>
    },
    {
        id: 11,
        title: "Aranyos kép - Christmas is a time when you get homesick - even when you are home ",
        content: <>
            <h2>11. nap</h2>
            <div>
                <img src="assets/bayer2023/dec11.jpg" alt="" className={'my-4 rounded-lg hidden lg:block lg:max-w-[580px] 2xl:max-w-full'}/>
                <img src="assets/bayer2023/dec11-mob.jpg" alt="" className={'my-4 lg:hidden'}/>
            </div>
        </>
    },
    {
        id: 13,
        title: "Vicces videó - Gordon Goose: Christmas Tree!",
        content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/iDicwfAhDaM"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/iDicwfAhDaM"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    },
    {
        id: 14,
        title: "Karácsonyi adományozás - Cipősdoboz alapítvány",
        content:
            <>
                <h2>Karácsonyi adományozás - Cipősdoboz alapítvány</h2>
                <div>
                    <a href="https://ciposdoboz.hu/" target={"_blank"}><img src="assets/bayer2023/dec14.jpg" alt="" className={'my-4 rounded-lg hidden lg:block lg:max-w-[580px] 2xl:max-w-full'}/></a>
                    <a href="https://ciposdoboz.hu/" target={"_blank"}><img src="assets/bayer2023/dec14-mob.jpg" alt="" className={'my-4 lg:hidden'}/></a>
                </div>
            </>
    },
    {
        id: 16,
        title: "Tojáslikőr",
        content:
            <>
                <h2>Tojáslikőr</h2>
                <div>
                    <img src="assets/bayer2023/tojaslikor.jpg" alt="" className={'my-4 rounded-lg'}/>
                </div>
                <h4>Ez a tojáslikőr nagy sikert aratott a szerkesztőségben. Sokak szerint kicsit erősre sikerült, de mindenkinek nagyon ízlett. Ha nem akarjátok túl erősre, akkor a rumból kicsit tegyetek kevesebbet bele, de szerintem ennyi karácsonykor simán belefér. :D</h4>
                <div className="grid md:grid-cols-5 gap-x-2">
                    <div className={'md:col-span-2'}>
                        <h3>Hozzávalók</h3>
                        <p>körülbelül 1 literhez</p>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>4 db tojássárgája</li>
                            <li>400 ml habtejszín</li>
                            <li>100 ml tej</li>
                            <li>1 csomag vaníliás cukor</li>
                            <li>3 ek cukor</li>
                            <li>1 mk őrölt fahéj</li>
                            <li>350 ml fehér rum</li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>Ahogy bejgli nélkül, úgy tojáslikőr nélkül sincs karácsony, legalábbis sok helyen elengedhetetlen kísérője az ünnepnek ez az ital. Érdekesség, hogy a tojáslikőr fogyasztásának karácsonyi hagyománya eredetileg az Egyesült Királyságból és az Egyesült Államokból ered. Nagyon sokan a boltban vásárolják, de házilag is legalább annyira ütős verzió készíthető. Szerencsére nem kell hozzá semmi spéci alapanyag, vagy nem kell órákig a konyhában álldogálni. Egyszerű, de ha egyszer megcsináljátok, sajnos hamar el tud fogyni.</p>
                        <h4 className={'mb-2'}>A recept:</h4>
                        <p>A tejszínt, a tejet, a tojássárgájákkal, a cukorral, a vaníliás cukorral és a fahéjjal összekeverjük. Ezután egy fém tálban vízgőz felett folyamatos kevergetés mellett besűrítjük úgy, hogy csak kb. 90 fokig melegítjük, mert ha felforr, akkor kicsapódhat a tejszín.</p>
                        <p>Ha besűrűsödött, akkor kicsit hűlni hagyjuk, és hozzáadjuk a rumot. Hűtőben tárolva két hétig simán tárolható, bár azalatt úgyis elfogy. Persze, azért csak mértékkel fogyasszátok, nehogy baj legyen belőle</p>
                        <p><strong>Forrás:</strong> <a href="https://streetkitchen.hu/italok/klasszikus-tojaslikor/" target={"_blank"}>https://streetkitchen.hu/italok/klasszikus-tojaslikor/</a></p>
                    </div>
                </div>
            </>
    },
    {
        id: 17,
        title: "11 csodás téli kirándulóhely, ha magad mögött hagynád a város zaját",
        content:
            <>
                <h2>11 csodás téli kirándulóhely, ha magad mögött hagynád a város zaját</h2>
                <p><strong>Eleged van a vásári forgatagból, a zsúfolt jégpályákból és a januári szürkeségből? Hagyd magad mögött a város zaját, és vessz el a téli nyugalom birodalmában Magyarország legszebb téli kirándulóhelyein!</strong></p>
                <h3 className={'mt-2'}>Lillafüred</h3>
                <p>A Miskolchoz tartozó Lillafüred az év minden szakában mesebeli látványt kínál, de legszebb arcát télen, hóhulláskor mutatja. A fehér fák erdején áttörő Lillafüredi Palotaszálló előkelő sziluettje és a mellette elterülő, kis szerencsével fagyott felszínű Hámori-tó szemet kápráztató együttese valóban képeslapra kívánkozik, de érdemes legalább egy fél napot rászánni a környékre, és felkeresni és az egész évben nyitva tartó Vendéglő a Pisztrángoshoz éttermet, a látogatókat reggel 9 és délután 2 között fogadó Szent István- és Anna-barlangokat, ahol a látványos cseppkőformációk mellett telelő denevérekkel is találkozhatunk, valamint az ország legmagasabb, 20 méteres, mesterségesen kialakított vízesését és a zordon kinézetű újmassai Őskohót.</p>
                <img src="assets/bayer2023/lillafured.jpg" alt=""/>
                <h3 className={"mt-5"}>Egerszalóki sódomb</h3>
                <p>Hazánk egyik legkülönlegesebb természeti csodája az Eger határától 5 kilométerre lévő egerszalóki sódomb, melyhez hasonló képződmény mindössze két másik helyen fordul elő a világban, a Yellowstone Nemzeti Parkban és a törökországi Pamukkaléban. A sódomb, habár ősi eredetűnek látszik, valójában alig 60 éves: 1961-ben történt ugyanis, hogy szakemberek 400 méter mély lyukat fúrtak a térségben, kőolaj után kutatva, ám fekete arany helyett „mindössze” forró gyógyvizet találtak. Néhány évre rá megépítették az első medencét, majd a nyolcvanas években kifúrták a második kutat is, mára pedig hatalmas wellnessközpont nőtte körül a folyamatosan növekvő, 1200 négyzetméteres mészkőlerakódást.</p>
                <img src="assets/bayer2023/egerszalok.jpg" alt=""/>
                <h3 className={"mt-5"}>Kavicsos-tó</h3>
                <p>A Csepel-sziget méltatlanul alul helyezkedik el a budapestiek képzeletbeli kirándulóhely-listáján, holott a 257 négyzetkilométer területű sziget nincs híján természeti kincseknek, sem történelmi emlékeknek. Az ország legmagasabb építményének, a 314 méteres lakihegyi adótoronynak is otthonául szolgáló Szigetszentmiklós határában fekszik a drónfotósok és horgászok körében hatalmas népszerűségnek örvendő Kavicsos-tó és a magyar Maldív-szigeteknek is becézett, főként hétvégi házakkal beépített szigetrendszere. Hazánk sok más tavához hasonlóan a Kavicsos is mesterséges eredetű: nevét a helyén egykor működő bányáról kapta, ahol a panelházak építéséhez elengedhetetlen kavicsokat kotorták ki.</p>
                <img src="assets/bayer2023/kavicsos-to.jpg" alt=""/>
                <h3 className={"mt-5"}>Búbánatvölgy</h3>
                <p>Nevével ellentétben a Dunakanyar eme rejtett gyöngyszeme nem ad sok okot a bánkódásra: a Budapesttől nagyjából egyórás autóútra, a Szamár-hegy és a Hosszú-hegy között a Dunáig húzódó Búbánatvölgyet azok számára ajánljuk, akik egy kis csendre, a világ zajától való elvonulásra vágynak. A hangulatos horgásztavak láncára felfűzött természetes mélyedés könnyen megközelíthető, ezért rendkívül közkedvelt a túrázók között, és a közelben lévő, felújított játszótér miatt még a nagycsaládosok számára is kiváló kiránduló helyszín, de bográcsozásra és szalonnasütésre is van lehetőség. A P∆ és a P+ túrajelzéseket követve pazar panorámák, sűrű erdők és tóparti idill fogad minket.</p>
                <img src="assets/bayer2023/bubanatvolgy.jpg" alt=""/>
                <h3 className={"mt-5"}>Tatai Fényes Tanösvény</h3>
                <p>Tata télen is elragadóan bájos, 18 állomásos, másfél kilométer hosszú, cölöpökön nyugvó ökoturisztikai útvonala a vadregényes lápvidékre kalauzol el minket, megismertetve velünk a titokzatos láperdők és 20-22 Celsius fokos hőmérsékletű karsztforrások egyedülálló világát. A jelentős madárfaunával és növénytársulásokkal büszkélkedő vidéket fentről is megcsodálhatjuk a Nagy-Égeresben található kilátótorony tetejéről, illetve a kalandkedvelők kipróbálhatják a kézi erővel működtethető csónakot vagy a függőhidat is. A reggel 9 és délután 4 között látogatható Fényes tanösvény látogatóközpontja a helyi élővilágot bemutató gyerekbarát kiállításokkal teszi fel az i-re a pontot.</p>
                <img src="assets/bayer2023/tata.jpg" alt=""/>
                <h3 className={"mt-5"}>Derítő-tó</h3>
                <p>Ha már Tata, a város központjától néhány kilométerre keletre megbújó Derítő-tó mellett sem mehetünk el szó nélkül. A helyi szénbányák úszó üledékének felfogására 1961-ben létrehozott mesterséges állóvíz (hivatalos nevén Által-éri ülepítő) a kidőlt fák biztosította búvóhelyeknek köszönhetően ma igazi horgászparadicsom, melynek télen-nyáron halban gazdag vizét néhány éve a kirándulók is felfedezték maguknak. A 32 hektáros tó partján, valamint az északról a vízbe nyúló, a szárazfölddel egy keskeny stéggel összekötött szigetecskén romantikus faházak között andaloghatunk, gágogó és csicsergő madarak zenéjére, a téli fagy csengő dalára.</p>
                <img src="assets/bayer2023/derito-to.jpg" alt=""/>
                <h3 className={"mt-5"}>Cseszneki vár</h3>
                <p>A Valahol Európában forgatási helyszíneként is ismert, sziklaszirten magasodó cseszneki vár a Bakony egyik páratlan történelmi értéke. A tatárjárás után gótikus stílusban épült, hosszú és dicső életű vár eredetileg az Alföldről Győrbe vezető sóutat volt hivatott védelmezni, a török harcok idején végvárként teljesített szolgálatot, a 18. században pedig barokk ízlés szerint átépített várkastélyként az Esterházy hercegek egyik szálláshelyeként működött, mígnem egy földrengés és az azt követő tűzvész végleg megpecsételte sorsát. A Nemzeti Várprogram keretében részlegesen helyreállított, kutyabarát várból remek kilátás nyílik a hófödte Bakonyra, a 82-es főút melletti parkolóból pedig magára a várra.</p>
                <img src="assets/bayer2023/cseszneki-var.jpg" alt=""/>
                <h3 className={"mt-5"}>Csóványosi kilátó</h3>
                <p>Az izgalmakban és látványosságokban gazdag túraútvonalairól híres Börzsöny a téli hónapokban is ideális úti cél az aktív kikapcsolódás szerelmeseinek. Legmagasabb pontját a 938 méteres Csóványoson találjuk, melynek csúcsára mind a négy égtáj irányából felkapaszkodhatunk, hogy aztán az 1978-ban geodéziai mérőtoronyként felhúzott, közel 23 méter magas kilátóból levegő után kapkodva csodálkozzunk rá az elénk táruló, a Magas-Tátrától a Schneebergig terjedő, hegyvidéki körpanorámára. Ha ránk hallgattok, a csóványosi kilátót a Királyréthez közel eső Cseresznyefa parkolóból induló 6 órás körtúra keretében hódítjátok meg, amihez a részletes útvonalat megtaláljátok weboldalunkon, a 10 kihagyhatatlan látnivaló a télen is varázslatos Börzsönyben című cikkünkben.</p>
                <img src="assets/bayer2023/csovanyos.jpg" alt=""/>
                <h3 className={"mt-5"}>Kékestető</h3>
                <p>Ha van hely Magyarországon, ahová kötelező télen ellátogatni, az a 1014 méteres tengerszint feletti magasságával minden egyéb hazai hegycsúcsot maga mögé utasító Kékestető. A kristálytiszta hegyi levegője és különleges éghajlati jellemzői miatt 1963-ban klimatikus gyógyhellyé nyilvánított Kékestetőről indul az ország leghosszabb lesiklópályája, a síelők, snowboardosok és szánkózók legnagyobb örömére, míg messziről is jól látható, 180 méter magas tévétornya szó szerint lélegzetelállító kilátással kecsegtet az alattunk elterülő, ezüstösen csillogó, hófödte erdei útvonalakat rejtő tájra.</p>
                <img src="assets/bayer2023/kekesteto.jpg" alt=""/>
                <h3 className={"mt-5"}>Ilona-völgyi vízesés</h3>
                <p>A Keleti-Mátrában, az Ilona-patak által kivájt völgyben találjuk hazánk legmagasabb természetes zuhatagát: a 10 méter magasból, egy függőleges sziklafal V-alakú felső hasadékából vékony sugárban a mélybe zúduló víztömeget legkönnyebben a Parádfürdőtől induló zöldkör-turistajelzésen tudjuk megközelíteni: a kilencállomásos, 6,5 kilométer hosszú tanösvény százéves gesztenyefasor, a Rákóczi-fa, a Szent István-csevicekút és az Ördöggátak néven ismert miocén kori kőzetképződmény érintésével, a patakmeder vonalában haladva tárja elénk a jégbe fagyva még megkapóbb vízesés látványát.</p>
                <img src="assets/bayer2023/ilona-volgy.jpg" alt=""/>
                <h3 className={"mt-5"}>Sástó</h3>
                <p>Az elmúlt években a Fekete Zaj fesztivál helyszíneként a köztudatba bevonult mátrai Sástó az ország legmagasabban fekvő tava. A tó mellett egy 53 méter magas, olajfúrótoronyból kialakított kilátóból nézhetünk végig a tengerszint felett több mint 500 méterrel elterülő tájon, míg a környék természeti értékeivel megismerkedni kívánkozók a mátrafüredi Harkály Házhoz tartozó, 8 állomásos, 3,5 kilométer hosszú, Cincér tanösvény nevű túraútvonalat is bejárhatják. Sástó egykori kőbányájának területén 2014-ben nyílt meg az Oxygen Adrenalin Park, ami egy 930 méteres téli bobpályával és felejthetetlen élményekkel várja az egész családot.</p>
                <img src="assets/bayer2023/sasto.jpg" alt=""/>
                <p className={"mt-4"}><strong>Forrás:</strong> <a href="https://funzine.hu/2022/01/02/utazas/11-csodas-teli-kirandulohely-ha-magad-mogott-hagynad-a-varos-zajat/" target={"_blank"}>https://funzine.hu/2022/01/02/utazas/11-csodas-teli-kirandulohely-ha-magad-mogott-hagynad-a-varos-zajat/</a></p>
            </>

    },
    {
        id: 20,
        title: "Karácsonyi asztali dísz termésekből videó",
        content:
            <>
                <h2>Karácsonyi asztali dísz termésekből</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/O8Ttdk5Xf6w"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/O8Ttdk5Xf6w"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    }
    ,
    {
        id: 22,
        title: "Aranyos kép - Going to lay under the christmas tree..",
        content:
            <>
                <img src="assets/bayer2023/cicas.png" alt=""/>
            </>
    },
    {
        id: 9,
        title: "Zene - Nat King Cole - The Christmas Song",
        content:
            <>
                <h2>Hangolódj az ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/wKhRnZZ0cJI"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/wKhRnZZ0cJI"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
            </>
    },
    {
        id: 13,
        title: "Podcast műsorok karácsonyra",
        content:
            <>
                <h2>Podcast műsorok karácsonyra</h2>
                <div className={'flex flex-col md:flex-row gap-8 justify-center my-10'}>
                    <div className={'flex flex-col items-center gap-4'}>
                        <img src="assets/alfa/az-elet-meg-minden.jpg" alt="" className={'rounded-lg max-w-[200px]'}/>
                        <a href="https://open.spotify.com/show/7cNwO6NYlhG9FxpFZqhMdH?si=cb0256ce832c4f44" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Meghallgatom</a>
                    </div>
                    <div className={'flex flex-col items-center gap-4'}>
                        <img src="assets/alfa/alinda-podcast.jpg" alt="" className={'rounded-lg max-w-[200px]'}/>
                        <a href="https://open.spotify.com/show/4q0CJej0JcZVhK2Weesq8q?si=fb7010964d614136" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Meghallgatom</a>
                    </div>
                </div>

            </>
    },
    {
        id: 17,
        title: "Zene - Idina Menzel & Michael Bublé - Baby It's Cold Outside",
        content:
            <>
                <h2>Hangolódj az ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/6bbuBubZ1yE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/6bbuBubZ1yE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <p className={'text-brandDark lg:text-base'}>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</p>
            </>
    },
    {
        id: 21,
        title: "Karácsonyfa fali dísz videó",
        content:
            <>
                <h2>Karácsonyfa fali dísz videó</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/3o-HfEKmo94"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/3o-HfEKmo94"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
            </>
    }
]

