import logo from './logo.svg';
import './App.css';
import {Days} from "./Store/days";
import { useState } from 'react';
import Modal from "./Components/Modal/Modal";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState();
  
  return (
    <div className="bg-[#fcf6f0] p-6 lg:p-12 min-h-screen">
      <h1 className={"text-center text-2xl mb-5"}>Tartalmak</h1>
      {
        Days.map((day, index)=>{
          return (
            <div className={"mx-auto w-full lg:max-w-2xl flex justify-between items-center border-b border-gray-300 py-2"}>
              <p>{day.title ?? "-----"}</p>
              <button className="py-2 px-4 bg-emerald-950 rounded-md text-white" onClick={() => {
                setIsModalOpen(true);
                setSelectedContent(index);
              }}>Megnyitás</button>
            </div>
          );
        })
      }
      {(isModalOpen ) && <Modal content={Days[selectedContent].content} setSelectedContent={setSelectedContent} setIsModalOpen={setIsModalOpen} />}
    </div>
  );
}

export default App;
